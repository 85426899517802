import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./../apis/auth-helper.js"; // Asegúrate de que este archivo tenga la lógica de autenticación

const RutaMercadoProtegido = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthenticated = auth.isAuthenticated();
        if (isAuthenticated) {
          // Si el usuario está autenticado, renderiza el componente
          return <Component {...props} />;
        } else {
          // Si no está autenticado, redirige al login y guarda la URL actual
          return (
            <Redirect
              to={{
                pathname: "/Identificarse",
                state: { from: props.location }, // Guarda la URL actual
              }}
            />
          );
        }
      }}
    />
  );
};

export default RutaMercadoProtegido;