import React, { Fragment, useState, useEffect } from "react";
import { DateUtils } from 'react-day-picker';
import { useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'
import { Redirect } from "react-router-dom"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
//import CartIcon from "@material-ui/icons/ShoppingCart"
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import HomeIcon from '@material-ui/icons/Home';
import DeleteForever from '@material-ui/icons/DeleteForever'

import { list } from './../../apis/api-producto.js'
import auth from './../../apis/auth-helper.js'
import { listUltimoPedidoUsMer } from './../../apis/api-pedido.js'
import { crearDirec } from './../../apis/api-user.js'

import ThemeGeroa from './../../components/ThemeGeroa.js'
import Boton from './../../components/botones/Boton.js'
import BotonCalendario from './../../components/botones/BotonCalendario.js'
import BotonSinSalto from './../../components/botones/BotonSinSalto.js'
import BotonValidacion from './../../components/botones/BotonValidacion.js';
//import RadioBotones from './../../components/radioButtons/RadioBotones.js'
import SelectorCantidad from './../../components/capturas/SelectorCantidad.js'
import SelectorDias from './../../components/capturas/SelectorDias.js'
import RadioEntregasMercado from './../../components/radioButtons/RadioBotonesMercado.js'
import RadioBotonesTipoDePago from './../../components/radioButtons/RadioBotonesTipoDePago.js'

import config from './../../config/config.js'
import { FiltraFechasEntrega, PrecioCliente } from './../../config/funcionesParticulares.js'
import { NumeroEsp, FechaLargaEsp, BuscaIndice, JsonAArray, FechaAaMmDdHhMmSs, FechaAaaaMmDdHhMm } from './../../config/funcionesPropias.js'

import { cargaDatosPagoTarjeta } from './RedsysPropia'

import { createVariosPedidos } from './../../apis/api-pedido.js'

const defaultImgProducto = require('./../../assets/images/defectos/producto_0000.png')
const defaultImgProductor = require('./../../assets/images/defectos/productor_0000.png')

const useStyles = makeStyles(theme => ({
    // color mas claro #caae6b
    // color Cabecera #BD9A46
    // color mas oscuro #846b31
    registro: {
        marginTop: '20px',
        minHeight: '65vh'
    },
    boton: {
        color: '#FFF',
        backgroundColor: '#1b5e20',
        textTransform: "none",
        marginRight: '10px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    },
    botonSubmit: {
        textTransform: "none",
        fontFamily: "Roboto",
        border: 0,
        borderRadius: 5,
        fontSize: "14px",
        [ThemeGeroa.breakpoints.down('md')]: { fontSize: '13px' },
        padding: "0px 10px 0px 10px !important",
        [ThemeGeroa.breakpoints.down('md')]: { padding: '0px 5px 0px 5px !important' },
        fontWeight: "400",
        color: "#FFF",
        backgroundColor: ThemeGeroa.palette.primary.main,
        maxHeight: '30px',
        minWidth: '100px',
        minHeight: '30px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: ThemeGeroa.palette.secondary.light,
            cursor: "hand",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "&:disabled": {
            backgroundColor: ThemeGeroa.palette.secondary.dark,
        },
    },
    cabeceraCarro: {
        display: "flex",
        //flexFlow: "row",
        justifyContent: 'flex-start',
        [theme.breakpoints.up("xs")]: { flexFlow: "row" },
        [theme.breakpoints.down("xs")]: { flexFlow: "column" },
        //border: "2px solid black",
    },
    cabeceraCarroLeft: {
        //width: '70%',
        [theme.breakpoints.up("xs")]: { width: '70%' },
        [theme.breakpoints.down("xs")]: { width: '100%' },
        //border: "2px solid blue",
    },
    cabeceraCarroRight: {
        marginTop: '10px',
        marginBottom: '10px',
        //width: '30%',
        [theme.breakpoints.up("xs")]: { width: '30%' },
        [theme.breakpoints.down("xs")]: { width: '100%' },
        // ¡¡Importante!! Esta linea despues de las anteriores. //
        textAlign: 'right',
    },
    pieCarro: {
        marginTop: '10px',
        display: "flex",
        flexFlow: "row",
        justifyContent: 'flex-end',
    },
    datosEntrega: {
        display: "flex",
        alignItems: "flex-start",
        [theme.breakpoints.up("xs")]: { flexFlow: "row", justifyContent: "flex-start" },
        [theme.breakpoints.down("xs")]: { flexFlow: "column", justifyContent: "space-between" },
        //border: '1px solid blue',
    },
    cabEntrega: {
        marginTop: '10px',
        //border: '1px solid green',
    },
    cabFechas1: {
        //marginRight: 20,
        [theme.breakpoints.up("xs")]: { marginRight: 20 },
        [theme.breakpoints.down("xs")]: { marginRight: 0 },
    },
    cabFechas2: {
        border: '2px solid red',
        //marginRight: 20,
        [theme.breakpoints.up("xs")]: { marginRight: 20 },
        [theme.breakpoints.down("xs")]: { marginRight: 0 },
    },
    fechaRepe: {
        marginTop: 10,
        display: "flex",
        flexFlow: "column",
    },
    dirEntrega: {
        //border: "1px solid green",
        minHeight: 63,
    },
    formDirecciones: {
        margin: theme.spacing(0),
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5,
        minWidth: 300,
        maxWidth: 400,
        //border: '2px solid red',
    },
    cabeceraTarjetaLeft: {
        //width: '70%',
        [theme.breakpoints.up("xs")]: { width: '50%' },
        [theme.breakpoints.down("xs")]: { width: '100%' },
        //border: "2px solid blue",
    },
    panelProducto: {
        marginBottom: '0px',
        boxShadow: 'none',
        //border: "2px solid blue",
    },
    cabeceraProducto: {
        display: "flex",
        //flexFlow: "row",
        //justifyContent: "space-between",
        //width: '100%',
        [theme.breakpoints.up("xs")]: { flexFlow: "row", justifyContent: "flex-start", width: "70%" },
        [theme.breakpoints.down("xs")]: { flexFlow: "column", justifyContent: "space-between", width: "100%" },
        //border: "1px solid green",
    },
    fotoProducto: {
        //marginLeft: '-20px'
        marginLeft: '0px',
        maxHeight: 80,
        //border: "1px solid pink",
    },
    cabeceraProductoDatos: {
        //marginLeft: '20px'
        marginLeft: '10px',
        //border: "1px solid red",
    },
    radioBoton: {
        marginTop: '20px',
        marginBottom: '0px',
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "start",
        [theme.breakpoints.up("xs")]: { flexFlow: "column", justifyContent: "flex-start" },
        [theme.breakpoints.down("xs")]: { flexFlow: "column", justifyContent: "space-between" },
        //border: "1px solid green",
    },
    cabeceraProductor: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "flex-start",
        //width: '30%',
        width: '30%',
        //border: "1px solid red",
    },
    fotoProductor: {
        marginLeft: '10px',
        //border: "1px solid green",
    },
    cabeceraProductorDatos: {
        marginLeft: '20px',
        width: '100%',
        //border: "1px solid blue",
    },
    masDatos: {
        marginTop: '-10px'
    },
    importeProducto: {
        color: '#1565c0',
        fontSize: 14,
        fontWeight: 600,
        marginLeft: 20,
        [theme.breakpoints.up("xs")]: { marginLeft: 10, display: "inline" },
        [theme.breakpoints.down("xs")]: { marginLeft: 0, display: "block" },
    },
    iconoPapelera: {
        display: "flex",
        flexFlow: "row",
        //flexFlow: "column",
        justifyContent: "flex-end",
    },
    cabConfirmar: {
        display: "flex",
        flexFlow: "row",
        justifyContent: 'flex-start',
    },
    cabConfirmarIzda: {
        marginLeft: '-20px',
        width: '70%',
    },
    cabConfirmarDcha: {
        marginTop: '10px',
        marginLeft: '70px',
        width: '30%',
    },
    cabLineasPedido: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
        width: '100%',
    },
    piePedido: {
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
        width: '100%',
    },
    cesta: {
        marginTop: 20,
        fontSize: '32px',
        color: ThemeGeroa.palette.primary.main
    },
    casa: {
        marginTop: 15,
        fontSize: '32px',
        color: ThemeGeroa.palette.primary.main
    },
    nuevaDir: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        marginBottom: 20,
        minWidth: 300,
        maxWidth: 400
    },
    textoCP: {
        //marginLeft: 10,
        marginTop: 20,
        marginRight: 20,
    },
    textoTop: {
        marginTop: 20,
    },
    botonDir: {
        marginTop: 20,
        //border: "1px solid blue",
        display: "flex",
        justifyContent: 'flex-end',
    },
    mensajeError: {
        color: '#ff3878',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-1px',
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },

}));



//let itemsCarrito = []
//let itemsCarritoCompletos = []

const CarroCompra = ({ t }) => {
    const classes = useStyles();

    const [redirectIdentificarse, setRedirectIdentificarse] = useState(false)
    const [pantallaFinal, setPantallaFinal] = useState('')
    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');

    //itemsCarrito = JsonAArray(valuesGlobales.carrito)
    const [carro, setCarro] = useState([])

    //const [datosUltimoPedido, setDatosUltimoPedido] = useState({})
    const [direccion, setDireccion] = useState({ actual: -1, nueva: false })
    const [direcciones, setDirecciones] = useState([])
    /*
    {
        tipo: 0, // 0 Domicilio, 1 Punto Entrega
        nombre: "",
        domicilio: {},
        puntoEntrega: {}
    }
    */

    const [fechasEntregaMercado, setFechasEntregaMercado] = useState([])
    const [importeMinimo, setImporteMinimo] = useState(0)
    //const [impedirBotonConfirmar, setImpedirBotonConfirmar] = useState(false)

    // Fechas Pedidos Repetidos. //
    const [fechasPedidos, setFechasPedidos] = useState([])
    const [openDialoFe, setOpenDialoFe] = useState(false)
    // Distintas Fechas Entrega. //
    const [distintasFechas, setDistintasFechas] = useState([])

    const [dialogoError, setDialogoError] = useState({ hayError: false, tipo: 0 })
    const [openDialog1, setOpenDialog1] = useState(false)
    const [openDialog2, setOpenDialog2] = useState(false)
    const [openDialog4, setOpenDialog4] = useState(false)

    const [formaPago, setFormaPago] = useState({ tipo: 'Recibo', pagoConTarjeta: false, iva: valuesGlobales.ivaUsuario })
    const [valoresPago, setValoresPago] = useState({
        mi_Ds_SignatureVersion: "",
        mi_Ds_MerchantParameters: "",
        mi_Ds_Signature: "",
    })


    const jwt = auth.isAuthenticated()

    // Mercado Actual. //
    const mercadoActual = valuesGlobales.mercadoActual


    let indiceDelMercado = BuscaIndice(valuesGlobales.mercados, "name", mercadoActual)
    const objMercado = valuesGlobales.mercados[indiceDelMercado]

    let permitirVarios = false
    let repitePedidos = false

    // Variables para la llamada a cargarDatosPagoTarjeta
    let numeroPedido;
    let totalTarjeta;
    let codigoIdioma;
    const [pedidoHook, setPedido] = useState(null);
    const [actualizar, setActualizar] = useState(0)
    const [totalConIvaHook, setTotalConIva] = useState("");
    let incrementar = () => {
        setActualizar(actualizar + 1)
    }
    let pedido;


    const numDecimales = (valuesGlobales.ivaUsuario === 0 ? -1 : 2)

    /* // Esta función no se usa //
    function tIva(t) {
        let ret
        if (t === "001") {
            ret = "21.00"
        } else if (t === '002') {
            ret = "10.00"
        } else if (t === '003') {
            ret = "4.00"
        } else if (t === '004') {
            ret = "0.00"
        } else if (t === '005') {
            ret = "5.00"
        }
        return ret
    }
    */

    let listaPedidos = valuesGlobales.pedidosAGuardar
    const [formaPagoSeleccionada, setFormaPagoSeleccionada] = useState("");

    /*********************************************************************************
     * Filtra las formas de pago dependiendo de si el usuario hace pedidos multiple
     * En ese caso, se elimina "Tarjeta" como forma de pago
     *********************************************************************************/
    let formasDePagoFiltradas = [];

    const filtrarFormasPago = (listaPedidos) => {
        // Verificar si objMercado y sus formasDePago existen
        console.log("objMercado: ", objMercado)
        if (!objMercado || !objMercado.formasDePago) {
            console.error("objMercado o sus formasDePago no existen.");
            formasDePagoFiltradas = []; // Si no existe, dejamos el array vacío
            return;
        }

        const numeroDePedidos = listaPedidos.length;
        const formasFiltradas = objMercado.formasDePago.filter(forma => {
            if (numeroDePedidos > 1 && forma.tipo === "Tarjeta") {
                return false;
            }
            return true;
        });

        formasDePagoFiltradas = formasFiltradas;
        console.log("Se han filtrado las formas de pago: ", formasDePagoFiltradas);
        console.log("Formas de pago anteriores: ", objMercado.formasDePago);
    };

    filtrarFormasPago(listaPedidos);

    /*****************************************************
     ****************************************************/


    useEffect(() => {
        if (objMercado !== undefined) {
            loadProductos()
        }
        // filtrarFormasPago(listaPedidos)
        // El array vacío indica que UseEffect sólo se carga una vez. //
        // eslint-disable-next-line
        // Para que ignore el error: React Hook useEffect has a missing dependencies: //
        // objMercado, loadProductos //
        // eslint-disable-next-line
    }, [])

    async function loadProductos() {
        // Guarda la pantalla en la que estamos
        setValuesGlobales({ ...valuesGlobales, paginaOrigen: '/CarroCompra' })
        let itemsCarrito = JsonAArray(valuesGlobales.carrito)
        /* Si hay algo en el carrito. */
        if (itemsCarrito.length > 0) {
            // Carga Productos (Puede haber cambio de existencias y precios)
            list().then((dataP) => {
                if (dataP.error) {
                    console.log(dataP.error)
                }
                else {
                    // Quita los productos no visibles
                    dataP = dataP.filter((producto) => producto.visible)
                    //console.log('itemsCarrito 1 = ', itemsCarrito)
                    // Mapea el carro actual para localizar todos los datos de los productos del carro
                    let itemsCarritoCompletos = itemsCarrito.map((linea, i) => {
                        let producto = dataP[BuscaIndice(dataP, "_id", linea[0])]
                        let tp2 = BuscaIndice(producto.tarifas, "numero", mercadoActual)
                        let fechas = producto.tarifas[tp2].fechas.length !== 0 ? FiltraFechasEntrega(producto.tarifas[tp2].fechas, objMercado.dias) : []
                        let feP = []
                        fechas.map((f, i) => {
                            ///if(i <= 3) {
                            feP.push({//elemento: FechaLargaEsp(new Date(f), valuesGlobales.idioma),
                                seleccion: i === 0 ? true : false,
                                fecha: new Date(f)
                            })
                            ///}
                            return null
                        })
                        return {
                            "id": linea[0],
                            "canPedida": linea[1],
                            //
                            "codigo": producto.name,
                            "lote": producto.lote,
                            "unidad": valuesGlobales.unidades[producto.unidad],
                            "existenciasActuales": producto.quantity,
                            "controlEx": producto.controlEx,
                            "ingredientes": producto.ingredientes !== undefined ? producto.ingredientes : '',
                            "indicaciones": producto.indicaciones !== undefined ? producto.indicaciones : '',
                            "iva": producto.iva,
                            "description": producto.description,
                            "description2": producto.description2,
                            //
                            "tiendaId": producto.shop._id,
                            "tiendaCodigo": producto.shop.name,
                            "tiendaNombre": producto.shop.description,
                            "tiendaEmail": producto.shop.owner.email,
                            "tiendaPoblacion": producto.shop.owner.direc[0].poblacion,
                            "tiendaProvincia": producto.shop.owner.direc[0].provincia,
                            //
                            "precioCompra": producto.tarifas[tp2].tarifa,
                            "notas": producto.tarifas[tp2].notas,
                            "fechas": feP
                        }
                    })

                    // Objeto Direcciones. //
                    /*
                    {
                        tipo: 0, // 0 Domicilio, 1 Punto Entrega
                        nombre: "",
                        domicilio: {},
                        puntoEntrega: {}
                    }
                    */
                    // Cargar los Puntos de Recogida del Mercado. //
                    let tpDir = objMercado.puntosDeRecogida.map(pEnt => {
                        return {
                            tipo: 1,
                            nombre: pEnt.puntoRecogida + " - " + pEnt.localidad.nombre,
                            puntoEntrega: pEnt,
                            gastos: pEnt.localidad.gastos
                        }
                    })
                    // Si el usuario está identificado. //
                    if (valuesGlobales.emailUsuario !== '') {
                        // Si el mercado Admite entrega a Domicilio. //
                        if (objMercado.localidades.length > 0) {
                            // Direcciones Cliente. //
                            let tp = jwt.user.direcciones.map(dir => {
                                return {
                                    tipo: 0,
                                    nombre: dir.direccion + " - " + dir.poblacion,
                                    domicilio: dir
                                }
                            })
                            tpDir = [...tpDir, ...tp]
                        }
                        // Buscar la Dirección seleccionada en el último pedido. //
                        let nombre = ""
                        // Cargar ultimo Pedido. //
                        // Establece las posibilidades de modalidades de Entrega, poner en funcion del ultimo pedido (si existe)
                        // Si está identificado coger datos del último pedido en ese mercado (para saber la forma de entrega)
                        listUltimoPedidoUsMer({ userId: jwt.user.codigo, mercado: objMercado.name }, { t: jwt.token })
                            .then((ultimoPedido) => {
                                //console.log('Ultimo Pedido = ', ultimoPedido)
                                if (ultimoPedido.error) {
                                    console.log("Error Ultimo Pedido = ", ultimoPedido.error)
                                } else {
                                    let tEntrega = "0"
                                    if (ultimoPedido.length > 0) {
                                        let uP = ultimoPedido[0]
                                        // Tipo de Entrega del último Pedido
                                        tEntrega = uP.tipoEntrega === "D" ? "0" : "1"
                                        // Direccion del último Pedido
                                        if (tEntrega === "0") {
                                            // Pon por defecto la dirección del último pedido
                                            nombre = uP.dir.direccion + " - " + uP.dir.poblacion
                                        } else {
                                            // Pon por defecto el punto de entrega del último pedido
                                            nombre = uP.puntoEntrega.nombre + " - " + uP.puntoEntrega.localidad
                                        }
                                        // Si el mercado No admite a Domicilio
                                        if (tEntrega === "0" && objMercado.localidades.length === 0) {
                                            nombre = ""
                                        }
                                    }
                                    // Localizar la dirección en el array. //
                                    setDireccion({ ...direccion, actual: tpDir.findIndex(dir => dir.nombre === nombre) })
                                }
                            })
                    }
                    // Si no hay ninguna Dirección. //
                    if (tpDir.length === 0) {
                        tpDir = [{ tipo: 2, nombre: t("Carro.MiDireccion") }]
                    }
                    // Guardar array de direcciones. //
                    setDirecciones(tpDir)

                    // Gancho importe minimo
                    setImporteMinimo(objMercado.minimo)
                    // Fechas Entrega Mercado. //
                    let feM
                    // Si sólo hay un Producto y tiene Fechas de Entrega particulares. //
                    if (itemsCarrito.length === 1 && itemsCarritoCompletos[0].fechas.length > 0) {
                        feM = [...itemsCarritoCompletos[0].fechas]
                        // Quitar para que no lo pinte. //
                        itemsCarritoCompletos[0].fechas = []
                    }
                    else {
                        //console.log('Objeto Mercado: objMercado = ',objMercado)
                        feM = FiltraFechasEntrega(objMercado.calendario, objMercado.dias)
                            .map((f, i) => {
                                return { //elemento: FechaLargaEsp(new Date(f), valuesGlobales.idioma),
                                    seleccion: i === 0 ? true : false,
                                    fecha: new Date(f)
                                }
                            })
                    }
                    setFechasEntregaMercado(feM)

                    // Mostrar error si no hay fechas definidas. //
                    if (feM.length === 0) setDialogoError({ hayError: true, tipo: 5 })

                    // Entrar con la 1ª fecha de entrega del mercado (Xabier me indica que no)
                    //////////////////////if (feM.length > 0 ) setFechasPedidos([...fechasPedidos, feM[0]])

                    // Poner los items en el gancho
                    setCarro(itemsCarritoCompletos)
                }
            })
        }
    }



    const handleSelectorDia = (dia) => {
        let repe = [...fechasPedidos]
        // Localizar el día en FechasEntregaMercado. //
        let index = repe.findIndex(f => DateUtils.isSameDay(f.fecha, dia))
        if (index !== -1) {
            // Cambiar seleccion. //
            //let sel = fechasPedidos[index].seleccion
            //fechasPedidos[index].seleccion = !sel
            // Eliminar del Array. //
            repe.splice(index, 1);
        } else {
            // Buscar indice en fEM. //
            index = fechasEntregaMercado.findIndex(f => DateUtils.isSameDay(f.fecha, dia))
            // Hacer copia del Objeto. //
            let tp = { ...fechasEntregaMercado[index] }
            tp.seleccion = true
            repe.push(tp)
        }

        // Si el mercado no tiene fiechas de Entrega. //
        // Esto no hace falta si no dejamos abrir el calendario, ni seleccionar días. //
        if (fechasEntregaMercado.length === 0) {
            setDialogoError({ hayError: true, tipo: 5 })
        } else {
            // Ordenar el array. //
            setFechasPedidos(repe.sort((p1, p2) => {
                let ret = 0
                if (p1.fecha > p2.fecha) { ret = 1 }
                if (p1.fecha < p2.fecha) { ret = -1 }
                return ret
            }))
        }
    }


    const handleChangeDireccion = (event) => {
        let dir = event.target.value


        // Si No es nueva Dirección. --
        if (dir <= direcciones.length) {
            // Si no es Mi Dirección. //
            if (direcciones[dir].tipo !== 2) {
                setDireccion({ ...direccion, actual: dir })
            } else {
                // Ir a identificarse. //
                setValuesGlobales({ ...valuesGlobales, paginaDestino: '/CarroCompra' })
                setRedirectIdentificarse(true)
            }
        }
    }


    const handleNuevaDireccion = () => {
        setDireccion({ ...direccion, nueva: true })
    }


    const handleDialoFe = () => {
        setOpenDialoFe(false);
    };

    const handleDialogoError = (tipo) => event => {
        // Cerrar dialogo. //
        setDialogoError({ hayError: false, tipo: 0 })
        // Abrir Calendario entregas. //
        if (tipo === 1) setOpenDialoFe(true)
    };

    const handleCloseDialog1 = () => {
        console.log("handle close dialog 1")
        setOpenDialog1(false);
    };
    const handleAceptarDialog1 = () => {
        // Permitir varias fechas. //
        permitirVarios = true
        // forzar cierre.
        setOpenDialog1(false);
        // Volver a pintar //
        handleRealizar()
        console.log("handle dialog 1")
        //realizarPago()
    }

    const handleCloseDialog2 = () => {
        console.log("handle close dialog 2")
        setOpenDialog2(false);
    };
    const handleAceptarDialog2 = () => {
        // Dejar sólo la primera Fecha. //
        fechasPedidos.length = 1
        // forzar cierre.
        setOpenDialog2(false);
        console.log("handle dialog 2")
        // Volver a pintar //
        handleRealizar()
        //realizarPago()
    }

    const handleCloseDialog4 = () => {
        console.log("handle close dialog 4")
        setOpenDialog4(false);
    };
    const handleAceptarDialog4 = () => {
        // Mensaje con fechas repeticion. //
        repitePedidos = true
        // forzar cierre.
        console.log("handle dialog 4")
        setOpenDialog4(false);
        // Volver a pintar //
        handleRealizar()
        //realizarPago()
    }


    function importeTotal1(lineas) {
        return lineas.reduce((a, b) => a + PrecioCliente(b.precioCompra, b.iva, valuesGlobales.ivaUsuario) * b.canPedida, 0)
    }


    const handleBorraProducto = index => event => {
        // Evita Apertura de panel
        event.stopPropagation();
        event.preventDefault();
        // Elimino del storage
        delete valuesGlobales.carrito[Object.keys(valuesGlobales.carrito)[index]]
        // Guarda en Local Storage
        localStorage.setItem('carrito', JSON.stringify(valuesGlobales.carrito))

        // Elimina el mercado actual si no hay items
        let mA = mercadoActual
        if (JsonAArray(valuesGlobales.carrito).length === 0) {
            mA = ''
            localStorage.removeItem('mercadoActual')
        }
        // Guarga en valuesGlobales
        setValuesGlobales({ ...valuesGlobales, mercadoActual: mA, numeroCarrito: JsonAArray(valuesGlobales.carrito).length })
        // Elimina del array de visualizacion
        carro.splice(index, 1)
    }


    const realizarPago = async () => {
        incrementar()
        //e.preventDefault(); // Evita el envío automático del formulario
        console.log("Entrando a realizar pago")
        console.log("Lista de pedidos fuera try: " + JSON.stringify(listaPedidos))
        try {
            let orderId;
            console.log("Lista de pedidos: " + JSON.stringify(listaPedidos))
            for (pedido of listaPedidos) {
                pedido.fPago = formaPagoSeleccionada
            }

            const data = await crearPedidos(jwt, listaPedidos);
            localStorage.setItem("pedidos", JSON.stringify(data))

            console.log("pedido: " + JSON.stringify(data[0]))


            // Verifica que data sea un array y extrae los IDs de los pedidos
            if (Array.isArray(data)) {
                // Crea un array con los IDs de los pedidos
                //const orderIds = data.map((order) => order._id);
                orderId = data[0].idPedido
                for (pedido of data) {
                    if (pedido.formaPago === "J") {
                        orderId = pedido.idPedido.toString()
                        let paramsGenerados = cargaDatosPagoTarjeta(totalConIvaHook, orderId.toString(), jwt.user.codigo, codigoIdioma);
                        // Modifica los valores del formulario de envía a Redsys
                        setValoresPago({
                            mi_Ds_SignatureVersion: paramsGenerados.signatureVersion,
                            mi_Ds_MerchantParameters: paramsGenerados.merchantParameters,
                            mi_Ds_Signature: paramsGenerados.signature,
                        });
                        console.log("params: " + paramsGenerados)
                        // Envía el formulario
                        document.getElementById("miForm01").submit();
                    }
                }
                console.log("data:" + data)



            } else {
                console.error("La respuesta de crearPedidos no es un array:", data);
            }
        } catch (error) {
            console.error("Error en el try al crear los pedidos:", error);
        }
    }




    // Realizar Pedido //
    const handleRealizar = () => {
        // Borrar forma de pago "Recibo" si el usuario no tiene la opcion
        /*
        if (!valuesGlobales.recibo) {
            objMercado.formasDePago = objMercado.formasDePago.filter(forma => forma.tipo !== "Recibo");
        }
            */


        console.log("Lista de pedidos: " + listaPedidos)
        console.log("Formas de pago del mercado: " + JSON.stringify(objMercado.formasDePago))
        
        // Organiza las líneas de compra
        let fecEntrega = ''
        //let fechaMercado = fechasEntregaMercado[BuscaIndice(fechasEntregaMercado, 'seleccion', true)].fecha
        let fechaMercado = fechasPedidos.length > 0 ? fechasPedidos[0].fecha : ''
        let distintasFechasEntrega = false
        let objFechas = {}

        let total = 0

        let productosEmail = carro.map((producto, i) => {
            // Acumular Total pedido. //
            total += (producto.canPedida * producto.precioCompra)
            // Localiza la fecha de entrega del producto
            if (producto.fechas.length > 0) {
                // Coger fecha particular del producto
                fecEntrega = producto.fechas[BuscaIndice(producto.fechas, 'seleccion', true)].fecha
                if ((distintasFechasEntrega === false) && ('' + fechaMercado !== '' + fecEntrega)) {
                    distintasFechasEntrega = true
                }
                // Añadir al array de fechas. //
                objFechas[FechaLargaEsp(new Date(fecEntrega), valuesGlobales.idioma)] += 1
            } else {
                // Coger fecha del mercado
                fecEntrega = fechaMercado
                console.log("else de fecha de entrega");
                // Añadir al array de fechas. //
                objFechas[FechaLargaEsp(new Date(fechaMercado), valuesGlobales.idioma)] += 1
            }
            return {
                'product': producto.id,
                "unidad": valuesGlobales.unidades[producto.unidad],
                'description': valuesGlobales.idioma === "Es" ? producto.description : producto.description2,
                'quantity': producto.canPedida,
                "lote": producto.lote,
                'price': producto.precioCompra,
                'iva': producto.iva,
                'fechaEntrega': fecEntrega,
                "tiendaNombre": producto.tiendaNombre,
                "tiendaEmail": producto.tiendaEmail,
                "tiendaPoblacion": producto.tiendaPoblacion,
                "tiendaProvincia": producto.tiendaProvincia
            }
        })

        // Ordenar por fecha de Entrega (de más cercana a más lejana)
        let tp = productosEmail.sort((p1, p2) => {
            let ret = 0
            if (p1.fechaEntrega > p2.fechaEntrega) { ret = 1 }
            if (p1.fechaEntrega < p2.fechaEntrega) { ret = -1 }
            return ret
        })
        productosEmail = tp

        // Datos Pedido. //
        let datosPedido = {}
        // Validar Localidad entrega. //
        let loc = []
        let miDireccion = direcciones[direccion.actual]
        // Si hay dirección y el usuario está identificado. //
        if (direccion.actual !== -1 && valuesGlobales.emailUsuario !== '') {
            // Validación de la Dirección Entrega. //
            if (miDireccion.tipo === 0) {
                // A domicilio
                datosPedido.tipoDeEntrega = "0"
                datosPedido.dir = miDireccion.domicilio
                datosPedido.puntoEntrega = ''
                // Si no existe esa localidad en las localidad de entrega a domicilio
                let cp = datosPedido.dir.codigoPostal
                loc = objMercado.localidades.filter(loc => (cp >= loc.codPostalD && cp <= loc.codPostalH))
            } else {
                // Punto de Entrega
                datosPedido.tipoDeEntrega = "1"
                //let pE = objMercado.puntosDeRecogida[datosUltimoPedido.numPunto]
                datosPedido.dir = ''
                let pE = miDireccion.puntoEntrega
                datosPedido.puntoEntrega = {
                    "nombre": pE.puntoRecogida,
                    "detalle": pE.puntoDetalles,
                    "localidad": pE.localidad.nombre,
                    "telefono": jwt.user.direcciones[0].telefono
                }
            }
            console.log("Direccion usuario: " + JSON.stringify(direcciones))
        }

        // Dialogos con mensajes antes del Final. //
        let dialogo = false
        while (true) {
            // Si el usuario no está identificado, se debe identificar
            if (valuesGlobales.emailUsuario === '') {
                setValuesGlobales({ ...valuesGlobales, paginaDestino: '/CarroCompra' })
                setRedirectIdentificarse(true)
                dialogo = true
                break
            }

            // Si No hay fecha de entrega. //
            if (fechasPedidos.length < 1) {
                setDialogoError({ hayError: true, tipo: 1 })
                dialogo = true
                break
            }

            // Si el Total es inferior al Mínimo. //
            // El total es Sin Iva (En pantalla se puede estar pintando con Iva Incluído)
            if (total < importeMinimo) {
                //console.log("Total < Minimo: ", total, importeMinimo)
                setDialogoError({ hayError: true, tipo: 2 })
                dialogo = true
                break
            }

            // Si no ha seleccionado ninguna dirección. //
            // Si no existe esa localidad en las localidad de entrega a domicilio
            if (direccion.actual === -1 || (miDireccion.tipo === 0 && loc.length === 0)) {
                setDialogoError({ hayError: true, tipo: 3 })
                dialogo = true
                break
            }

            //console.log('objeto mercado = ', objMercado, ' --- User = ', jwt.user)
            // Si hay Logística Completa y el Mercado no tiene Formas de Pago. //
            if (objMercado.logistica === "Completa" && objMercado.formasDePago.length < 1) {
                setDialogoError({ hayError: true, tipo: 4 })
                dialogo = true
                break
            }

            // Error si hay varios pedidos y la única forma de pago disponible es tarjeta
            if (objMercado.formasDePago.length === 1 && objMercado.formasDePago[0].tipo === "Tarjeta" && fechasPedidos.length > 1) {
                console.log("Fechas de pedidos: " + JSON.stringify(fechasPedidos))
                setDialogoError({ hayError: true, tipo: 6 });
                dialogo = true;
                break;
            }



            // Si hay varias fechas de entrega y queremos repetir, avisar e impedir. //
            if (distintasFechasEntrega && fechasPedidos.length > 1) {
                setOpenDialog2(true)
                dialogo = true
                break
            }

            // Si hay algún producto con fecha de entrega particular, mensaje. //
            if (Object.keys(objFechas).length > 1 && !permitirVarios) {
                setDistintasFechas(Object.keys(objFechas))
                setOpenDialog1(true)
                dialogo = true
                break
            }
            // Si repetimos pedidos y No hay fechas de Entrega Especiales. //
            if (fechasPedidos.length > 1 && !repitePedidos) {
                console.log("forma de pago: " + formaPago.tipo)
                setOpenDialog4(true)
                dialogo = true
                break
            }
            break
        }

        // Si no hay ningún diálogo abierto. //
        if (!dialogo) {
            // Forma de Pago (Por Defecto Productor). //
            let fPago = 'Productor'
            let nombreFPago = valuesGlobales.idioma === "Es" ? "Productor" : "Produktor"
            // Localizar forma Pago por defecto. //
            let num = 0
            while (objMercado.formasDePago.length > num) {
                let fpm = objMercado.formasDePago[num]
                // Si es Recibo y el usuario permite Recibo o
                // si es Transf. y el usuario permite Transf. o (21-10-2024 Siempre permite)
                // si es Tarjeta y no hay Repeticiones o
                // No es ni Recibo, Transferencia ni Tarjeta.
                if ((fpm.tipo === "Recibo" && jwt.user.recibo) ||
                    (fpm.tipo === "Transferencia" /*&& jwt.user.transferencia*/) ||
                    //(fpm.tipo === "Tarjeta" && fechasPedidos.length < 2) ||
                    (fpm.tipo === "Tarjeta") ||
                    !["Recibo", "Transferencia", "Tarjeta"].includes(fpm.tipo)) {
                    fPago = fpm.tipo
                    nombreFPago = valuesGlobales.idioma === "Es" ? fpm.textoEs : fpm.textoEu
                    break
                }
                num++
            }
            // Si es tarjeta/Tpv precios con Iva Incluido. //
            let iva = valuesGlobales.ivaUsuario
            if (iva === 0 && ["Tarjeta", "Tpv"].includes(fPago)) {
                iva = jwt.user.recargo ? 2 : 1
            }
            // Actualizar el gancho de forma de Pago con Tarjeta. //
            setFormaPago({ ...formaPago, tipo: fPago, pagoConTarjeta: (fPago === "Tarjeta"), iva: iva })
            // Pon gastos por defecto
            let gastosReparto = 0
            if (miDireccion.tipo === 0) {
                // Gastos de Reparto de la Localidad
                //let cp = datosPedido.dir.codigoPostal
                //let loc = objMercado.localidades.filter( loc => (cp >= loc.codPostalD && cp <= loc.codPostalH))
                if (loc.length !== 0) {

                    //console.log('GASTOS REPARTO localidad = ', loc[0].gastos)
                    gastosReparto = loc[0].gastos
                }
            } else {
                // Localizar gastos del Punto de entrega del mercado. //
                ////gastosReparto = objMercado?.puntosDeRecogida[datosPedido.numPunto]?.localidad?.gastos || 0
                //console.log('GASTOS REPARTO mi direccion = ', miDireccion)

                gastosReparto = miDireccion.gastos
            }
            //console.log('Gastos Calculados: gastosReparto = ', gastosReparto)

            // Todo OK
            let pedidoEmail = {
                user: jwt.user._id,
                customer_codigo: jwt.user.codigo.substr(jwt.user.codigo.length - 4, 4),
                customer_name: jwt.user.name,
                customer_email: jwt.user.email,
                products: productosEmail,
                tipoDeEntrega: datosPedido.tipoDeEntrega,
                dir: datosPedido.dir,
                puntoEntrega: datosPedido.puntoEntrega,
                gastos: gastosReparto,
                mercado: mercadoActual,
                fPago: fPago,
                nombreFPago: nombreFPago
            }

            ///////////////////////////////////////
            // Calcula los Pedidos para el Registro
            ///////////////////////////////////////
            let ivaUsuario = jwt.user.recargo ? 2 : 1
            let totalSinIva = 0
            let totalConIva = 0

            // Array de productos
            let productos = carro.map(producto => {
                //console.log('dentro del carro = ', producto)
                // acumular el total
                //total += producto.canPedida * PrecioCliente(producto.precioCompra, producto.iva, valuesGlobales.ivaUsuario)
                totalSinIva += Math.round(producto.canPedida * PrecioCliente(producto.precioCompra, producto.iva, 0) * 100) / 100
                totalConIva += Math.round(producto.canPedida * PrecioCliente(producto.precioCompra, producto.iva, ivaUsuario) * 100) / 100
                //console.log('producto al final = ', producto)
                return {
                    'product': producto.id,
                    'quantity': producto.canPedida,
                    'controlEx': producto.controlEx,
                    'description': valuesGlobales.idioma === "Es" ? producto.description : producto.description2,
                    'iva': producto.iva,
                    'lote': producto.lote,
                    'price': producto.precioCompra,
                    'shop': producto.tiendaCodigo,
                    'email': producto.tiendaEmail,
                    'fechaEntrega': pedidoEmail.products[BuscaIndice(pedidoEmail.products, 'product', producto.id)].fechaEntrega
                }
            })
            // Ordenar por fecha de Entrega (de más cercana a más lejana)
            let tp = productos.sort((p1, p2) => {
                let ret = 0
                if (p1.fechaEntrega > p2.fechaEntrega) { ret = 1 }
                if (p1.fechaEntrega < p2.fechaEntrega) { ret = -1 }
                return ret
            })
            productos = tp

            console.log("Tipo entrega usuario: " + pedidoEmail.tipoDeEntrega)
            // pedido a registrar
            const nuevoPedido = {
                user: jwt.user._id,
                fechaPedido: FechaAaaaMmDdHhMm(),
                customer_codigo: jwt.user.codigo,
                customer_name: jwt.user.name,
                customer_email: jwt.user.email,
                tipoEntrega: pedidoEmail.tipoDeEntrega === "0" ? "D" : "P",
                dir: pedidoEmail.dir,
                puntoEntrega: pedidoEmail.puntoEntrega,
                products: productos,
                mercado: mercadoActual,
                fPago: fPago,
                gastos: pedidoEmail.gastos
            };

            let fecha;
            let pedido = nuevoPedido;
            setPedido(nuevoPedido); // Actualiza el estado con el nuevo pedido



            console.log("Pedido actualizado:", pedido);
            fecha = pedido?.products?.[0]?.fechaEntrega; // Usamos optional chaining para evitar errores si pedido es null
            console.log("pedido:", pedido);


            ///////////////////////////////////////////////
            // Registra y envía por mail el/los Pedidos. //
            ///////////////////////////////////////////////
            let pedidosFinales = []

            /////////////////////////////////////////////
            // Por cada fecha de Repetición de Pedido. //
            /////////////////////////////////////////////
            // Si no estamos en Pedidos Repetidos, separa los pedidos necesarios
            if (fechasPedidos.length === 1) {
                //console.log('pedido.productos = ', pedido.products)
                // Genera por cada fecha diferente un pedido diferente
                let listaFechas = []
                let listaArticulos = []

                pedido.products.map(art => {
                    if ('' + art.fechaEntrega === '' + fecha) {
                        listaArticulos.push(art)
                    } else {
                        listaFechas.push({ fecha: fecha, articulos: listaArticulos })
                        fecha = art.fechaEntrega
                        listaArticulos = []
                        listaArticulos.push(art)
                    }
                    return null
                })

                listaFechas.push({ fecha: fecha, articulos: listaArticulos })

                // Generar un pedido diferente por cada fecha Entrega. //
                pedidosFinales = listaFechas.map(dato => {
                    // hacer una copia del pedido a registrar. //
                    let tp = { ...pedido }
                    tp.fechaEntrega = dato.fecha
                    tp.restaExistencias = true
                    // hacer una copia de las productos de esa fecha. //
                    tp.products = [...dato.articulos]
                    return tp
                })

            } else {
                // Cambia las fechas de todos los productos para cada fecha de entrega
                for (let l = 0; l < fechasPedidos.length; ++l) {
                    let tp = JSON.parse(JSON.stringify(pedido))
                    tp.fechaEntrega = fechasPedidos[l].fecha
                    tp.restaExistencias = (l === 0)
                    pedidosFinales.push(tp)
                }
            }

            // Acumular los Gastos de Reparto al Total del Pedido. //
            pedidosFinales.map(p => {
                if (p.restaExistencias) {
                    //totalSinIva += Math.round(PrecioCliente(p.gastos, "21.00", 0) * 100) / 100
                    //totalConIva += Math.round(PrecioCliente(p.gastos, "21.00", ivaUsuario) * 100) / 100
                    // Aplicar el Tipo Iva 001=21%
                    //totalSinIva += Math.round(PrecioCliente(p.gastos, "001", 0) * 100) / 100
                    //totalConIva += Math.round(PrecioCliente(p.gastos, "001", ivaUsuario) * 100) / 100
                    // Eneek: Nagore dice que los gastos no tienen Iva. Tipo 004=0% //
                    totalSinIva += Math.round(PrecioCliente(p.gastos, "004", 0) * 100) / 100
                    totalConIva += Math.round(PrecioCliente(p.gastos, "004", ivaUsuario) * 100) / 100
                }
                return null
            })
            pedidoEmail.fecha = pedido.fechaPedido
            pedidoEmail.totalSinIva = totalSinIva
            pedidoEmail.totalConIva = totalConIva
            pedidoEmail.ivaUsuario = iva
            // En el primero de los pedidos guarda las Repeticiones (Para los mails)
            if (fechasPedidos.length > 1) {
                //pedidosFinales[0].repeticiones = [...fechasPedidos]
                pedidoEmail.repeticiones = [...fechasPedidos]
            } else {
                //pedidosFinales[0].repeticiones = []
                pedidoEmail.repeticiones = []
            }
            //pedidoEmail.repeticiones = (fechasPedidos.length > 1)
            //
            //
            // Obten los parámetros del Pago con Tarjeta SOLO del primer PEDIDO
            //   No se permitirá pago con tarjeta de pedidos que se desglosan en 2, ni de
            //   pedidos repetitivos
            //
            totalConIva = Math.round(totalConIva * 100).toString()
            setTotalConIva(totalConIva)

            // Llamar api para recibir nº pedido y enviar eso como parametro
            numeroPedido = pedido.customer_codigo.substr(pedido.customer_codigo.length - 2, 2) + FechaAaMmDdHhMmSs().substr(2)

            codigoIdioma = valuesGlobales.idioma === "Es" ? "0" : "13"
            let paramsGenerados = cargaDatosPagoTarjeta(totalConIvaHook, numeroPedido, pedido.customer_codigo, codigoIdioma)
            //let paramsGenerados = cargaDatosPagoTarjeta(pedidosFinales, valuesGlobales.idioma, iva)
            // Mofifica los valores del formulario de envía a Redsys
            setValoresPago({
                mi_Ds_SignatureVersion: paramsGenerados.signatureVersion,
                mi_Ds_MerchantParameters: paramsGenerados.merchantParameters,
                mi_Ds_Signature: paramsGenerados.signature,
            })
            //
            //
            //Preparar los datos en Values Globales (no hay pago con tarjeta) o local storage (pago con tarjeta)
            localStorage.setItem('pedidosAGuardar', JSON.stringify(pedidosFinales))
            localStorage.setItem('pedidosAEnviar', JSON.stringify(pedidoEmail))
            setValuesGlobales({ ...valuesGlobales, origenRedsys: false, pedidosAGuardar: pedidosFinales, pedidosAEnviar: pedidoEmail })
            console.log("Pedidos a guardar: " + localStorage.getItem('pedidosAGuardar'))


            // Recoger la lista de pedidos, actualizar las formas de pago en base a ellos y seleccionar la forma de pago por defecto
            listaPedidos = JSON.parse(localStorage.getItem('pedidosAGuardar'))
            filtrarFormasPago(listaPedidos)
            setFormaPagoSeleccionada(formasDePagoFiltradas[0].tipo)
            for (pedido of listaPedidos) {
                pedido.fPago = formaPagoSeleccionada
            }
            console.log("Lista pedidos: ", JSON.stringify(listaPedidos))
            setPantallaFinal(pedidoEmail)
        }
        //console.log('Al salir de Realizar = ', valuesGlobales)
    }

    const NoTramitarPedido = () => {
        setPantallaFinal('')
    }


    const handleChangeCantidadProductosCarro = (name, i) => event => {
        // Modifica la cantidad
        carro[i].canPedida = event
        //No actualizar gancho porque se bloquea
        //setCarro({...carro})
        // Guarda en values Globales. //
        let carrito = valuesGlobales.carrito
        carrito[name] = event
        setValuesGlobales({ ...valuesGlobales, carrito: carrito })
        // Guarda en Local Storage
        localStorage.setItem('carrito', JSON.stringify(carrito))
    }


    const cambioEnFormaDePago = (event) => {
        let tipo = event.target.value
        let nombreFPago = ''
        console.log("Formas de pago mercado: " + objMercado.formasDePago)
        objMercado.formasDePago.map(fpm => {
            if (fpm.tipo === tipo) {
                nombreFPago = valuesGlobales.idioma === "Es" ? fpm.textoEs : fpm.textoEu
            }
            return null
        })
        // Si cambia el Tipo de Iva de la Forma Pago. //
        let iva = valuesGlobales.ivaUsuario
        if (iva === 0 && ["Tarjeta", "Tpv"].includes(tipo)) {
            iva = jwt.user.recargo ? 2 : 1
        }

        // Modificar datos de la pantalla final en Values Globales. //
        valuesGlobales.pedidosAGuardar.map(pedido => {
            pedido.fPago = tipo
            return null
        })
        valuesGlobales.pedidosAEnviar.fPago = tipo
        valuesGlobales.pedidosAEnviar.nombreFPago = nombreFPago
        valuesGlobales.pedidosAEnviar.ivaUsuario = iva
        // Si es pago con Tarjeta. //
        if (tipo === 'Tarjeta') {
            // Actualizar local Storage. //
            localStorage.setItem('pedidosAEnviar', JSON.stringify(valuesGlobales.pedidosAEnviar))
        }
        //setValuesGlobales({ ...valuesGlobales, pedidosAEnviar: tp})
        // Actualizar el gancho de forma de Pago con Tarjeta. //
        setFormaPago({ ...formaPago, tipo: tipo, pagoConTarjeta: (tipo === "Tarjeta"), iva: iva })
    }


    /*****************************************************************************************
     * 
     * 
     * ***************************************************************************************
     */

    function traducirFormaPago(listaPedidos) {
        // Validar que sea array
        if (!Array.isArray(listaPedidos)) {
            throw new Error("listaPedidos debe ser un array");
        }

        //Validar que el campo fPago exista y sea un string
        for (let ped of listaPedidos) {
            if (!ped || typeof ped.fPago !== 'string') {
                console.warn("Pedido inválido o falta la propiedad fPago:", pedido);
                continue; // Saltar este pedido
            }
        }

        for (let ped of listaPedidos) {
            console.log("Forma pago dentro de traducir(antes de traducir): ", ped.fPago)
            if (ped.fPago === 'Recibo' || ped.fPago === 'Reziboa' || ped.fPago === 'Ordainagiria') {
                ped.fPago = "R";
            } else if (ped.fPago === 'Tarjeta' || ped.fPago === 'Txartela') {
                ped.fPago = "J";
            } else if (ped.fPago === 'Transferencia' || ped.fPago === 'Transferentzia') {
                ped.fPago = 'T';
            } else if (ped.fPago === 'Tpv') {
                ped.fPago = 'V';
            } else if (ped.fPago === 'Productor' || ped.fPago === 'Produktorea' || ped.fPago === 'Ekoizlea') {
                ped.fPago = 'P';
            }
        }
        return listaPedidos;
    }

    async function crearPedidos(jwt, listaPedidos) {
        console.log("Entrando a crearPedidos")
        console.log("listaPedidos JSON: " + JSON.stringify({ orders: listaPedidos }))

        listaPedidos = traducirFormaPago(listaPedidos);
        console.log("Forma de pago traducida en pedido: " + JSON.stringify(listaPedidos))

        //Llamada a api para crear pedidos
        let data = await createVariosPedidos({ userId: jwt.user._id }, { t: jwt.token }, listaPedidos)
        console.log("data en crear pedidos: " + data)
        if (data.error) {
            console.log("Error:", data)
        }
        return data
    }


    // Esta funcion se tiene que ir, los mails los envia el backend
    async function enviarMailsPedidos(pedidoEmail, jwt) {
        // Se necesita una flag que indique si el pedido a sido informado por mail o no. Llamar a la api para recojer estos pedidos
        let data; // data deberia ser lo que devuelva la api

        for (let pedido of data) {
            if (pedido.restaExistencias) {
                if (pedidoEmail._id !== '') {
                    pedidoEmail._id = pedidoEmail._id + ', '
                }
                pedidoEmail._id = pedidoEmail._id + pedido._id
            }
        }
        vaciarCarro()
        // envioCorreos(pedidoEmail, jwt.token)
    }

    function vaciarCarro() {
        localStorage.removeItem('carrito')
        localStorage.removeItem('mercadoActual')
        setValuesGlobales({ ...valuesGlobales, mercadoActual: '', carrito: {}, numeroCarrito: 0 })
    }

    /*****************************************************************************************
     * 
     * 
     * ***************************************************************************************
     */


    const NuevaDireccion = () => {
        const [nuevaDir, setNuevaDir] = useState({ nom: '', dom: '', cp: '', pob: '', tel: '' })
        const [errors, setErrors] = useState({ nom: '', dom: '', cp: '', pob: '', tel: '' });

        const handleDirChange = dato => (event) => {
            let valor = event.target.value
            // Si el dato es el codigo postal. //
            if (dato === 'cp') {
                let poblacion = ""
                for (let index = 0; index < valuesGlobales.poblaciones.length; index++) {
                    if (valor >= valuesGlobales.poblaciones[index].codPostalD &&
                        valor <= valuesGlobales.poblaciones[index].codPostalH) {
                        poblacion = valuesGlobales.poblaciones[index].nombre
                        break
                    }
                }
                setNuevaDir({ ...nuevaDir, cp: valor, pob: poblacion })
            } else {
                setNuevaDir({ ...nuevaDir, [dato]: valor })
            }
        }

        const handleDirCancela = (event) => {
            event.preventDefault()
            setDireccion({ ...direccion, nueva: false })
        }

        async function handleDirGuarda(event) {
            event.preventDefault()
            // Valida que se haya rellenado el formulario
            setErrors(validaDir(nuevaDir))
            // Para actualizar. //
            setNuevaDir({ ...nuevaDir })
            // Si No hay errores. //
            if (errors.nom === '' && errors.dom === '' &&
                errors.cp === '' && errors.pob === '' &&
                errors.tel === '') {
                // Provincia dependiendo del Codigo Postal. //
                let cp = nuevaDir.cp.substr(0, 2)
                let nomPro = ''
                let pro = ['01', '20', '48'].indexOf(cp)
                if (pro !== -1) {
                    nomPro = ["ARABA", "GIPUZKOA", "BIZKAIA"][pro]
                }
                // direcciones actuales del Cliente. //
                let userDir = jwt.user.direcciones
                // Objeto con la nueva Direccion. //
                let objDir = {
                    nombre: nuevaDir.nom,
                    numero: ('00' + (Number(userDir[userDir.length - 1].numero) + 1)).slice(-3),
                    direccion: nuevaDir.dom,
                    codigoPostal: nuevaDir.cp,
                    poblacion: nuevaDir.pob,
                    provincia: nomPro,
                    pais: userDir[userDir.length - 1].pais,
                    telefono: nuevaDir.tel,
                    defecto: false
                }
                // Añadir la nueva. //
                userDir.push(objDir)
                // Guardar. //
                try {
                    let data
                    // Modificar Usuario. ¡NO SE USA! //
                    /*
                    let objUser = {
                        direc: userDir
                    }
                    */
                    // Modificar el Usuario. //
                    data = await crearDirec({ userId: jwt.user.codigo }, { t: jwt.token }, objDir);
                    // Procesar respuesta. //
                    if (data === undefined) {
                        throw t('Error al actualizar datos')
                    } else if (data.error) {
                        throw t(data.error)
                    }
                    // Actualizar Direcciones del Usuario. //
                    jwt.user.direcciones = data.direc
                    // Guardar en almacén. //
                    sessionStorage.setItem('jwt', JSON.stringify(jwt))
                    // Ultima dirección = Tamaño array ¡¡Sin añadir la nueva!! //
                    let ultimaDir = direcciones.length
                    // Actualizar Ganchos Direcciones. //
                    let tpDir = [{
                        tipo: 0,
                        nombre: nuevaDir.dom + " - " + nuevaDir.pob,
                        domicilio: objDir
                    }]
                    setDirecciones([...direcciones, ...tpDir])
                    // Posicionar en la Ultima y Cerrar la pantalla de la Nueva Dirección. //
                    setDireccion({ ...direccion, actual: ultimaDir, nueva: false })
                }
                catch (err) {
                    errors.nom = err
                    // Actualizar errores al registrar.
                    setErrors(errors)
                }
                // Si no hay Error. //
                if (errors.nom === '') {
                }
                else {
                }
            }
        }

        // Valida los campos. //
        const validaDir = (datos) => {
            // vaciar errores. //
            errors.nom = ''
            errors.dom = ''
            errors.cp = ''
            errors.pob = ''
            errors.tel = ''
            // name
            if (datos.nom.length === 0) {
                errors.nom = t('El nombre o razón social es obligatorio')
            } else if (datos.nom.length < 6) {
                errors.nom = t('El nombre debe tener al menos 6 caracteres')
            }
            // direccion
            if (datos.dom.length === 0) {
                errors.dom = t('El domicilio es obligatorio')
            }
            // código Postal
            if (datos.cp.length !== 5) {
                errors.cp = t('El Código Postal es obligatorio')
            }
            // telefono
            if (datos.tel.length === 0) {
                errors.tel = t('El teléfono es obligatorio')
            }
            // La localidad de Entrega debe estar en ese Mercado. //
            let loc = objMercado.localidades.filter(loc => (datos.cp >= loc.codPostalD && datos.cp <= loc.codPostalH))
            if (loc.length === 0) {
                errors.pob = t("El mercado actual no entrega en esta localidad")
            }
            return errors
        }

        return (
            <Grid container className={classes.nuevaDir}>
                <Grid item xs={12} className={classes.textoTop}>
                    <TextField
                        //className={classes.textoDir}
                        type="text"
                        required
                        label={t("Nombre")}
                        value={nuevaDir.nom}
                        fullWidth
                        onChange={handleDirChange('nom')}
                    />
                    {errors.nom !== '' &&
                        (<Typography className={classes.mensajeError}>
                            {errors.nom}
                        </Typography>)
                    }
                </Grid>
                <Grid item xs={12} className={classes.textoTop}>
                    <TextField
                        //className={classes.textoDir}
                        type="text"
                        required
                        label={t("Domicilio")}
                        value={nuevaDir.dom}
                        fullWidth
                        onChange={handleDirChange('dom')}
                    />
                    {errors.dom !== '' &&
                        (<Typography className={classes.mensajeError}>
                            {errors.dom}
                        </Typography>)
                    }
                </Grid>
                <Grid item xs={4} className={classes.textoCP}>
                    <TextField
                        //className={classes.textoDir}
                        type="text"
                        required
                        label={t("Cº Postal")}
                        value={nuevaDir.cp}
                        fullWidth
                        onChange={handleDirChange('cp')}
                    />
                    {errors.cp !== '' &&
                        (<Typography className={classes.mensajeError}>
                            {errors.cp}
                        </Typography>)
                    }
                </Grid>
                <Grid item xs={7} className={classes.textoTop}>
                    <TextField
                        //className={classes.textoDir}
                        type="text"
                        disabled={true}
                        required
                        label={t("Población")}
                        value={nuevaDir.pob}
                        fullWidth
                    //onChange={handleDirChange('pob')}
                    />
                    {errors.pob !== '' &&
                        (<Typography className={classes.mensajeError}>
                            {errors.pob}
                        </Typography>)
                    }
                </Grid>
                <Grid item xs={4} className={classes.textoTop}>
                    <TextField
                        //className={classes.textoDir}
                        type="text"
                        required
                        label={t("Telefono")}
                        value={nuevaDir.tel}
                        fullWidth
                        onChange={handleDirChange('tel')}
                    />
                    {errors.tel !== '' &&
                        (<Typography className={classes.mensajeError}>
                            {errors.tel}
                        </Typography>)
                    }
                </Grid>
                <Grid item xs={12} className={classes.botonDir}>
                    <Grid item xs={6} className={classes.botonDir}>
                        <BotonSinSalto
                            texto={t('General.Cancelar')}
                            accion={handleDirCancela}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.botonDir}>
                        <BotonSinSalto
                            texto={t('General.Aceptar')}
                            accion={handleDirGuarda}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const CabeceraFormada = (f) => {
        return (
            <Grid container className={classes.cabLineasPedido}>
                <Grid item xs={12}>
                    <Typography style={{
                        color: '#c62828',
                        fontSize: 14,
                        fontWeight: 500,
                        marginLeft: 10,
                        marginRight: 10
                    }}>
                        <Fragment>
                            <br />
                            ({t("Email.FechaEntrega")}: {FechaLargaEsp(f, valuesGlobales.idioma)})
                        </Fragment>
                    </Typography>
                </Grid>
            </Grid>
        )
    }


    const PieFormado = () => {
        let listaFechasPedidos = ''
        // Coger la fecha seleccionada y las fechas de Repetición. //
        //if (fechasPedidos.length > 0) {
        if (fechasPedidos.length > 1) {
            // Array con la fechas seleccionada sumando las fechas a repetir. --
            listaFechasPedidos = fechasPedidos.map(f => FechaLargaEsp(f.fecha, valuesGlobales.idioma)).join('; ')
        }
        return (
            <Grid container className={classes.piePedido}>
                <Grid container className={classes.cabLineasPedido}>
                    <Grid item xs={10}>
                        <Typography style={{ color: '#111111', fontSize: 14, fontWeight: 600, marginLeft: 10 }} display="inline">
                            {t("Email.GastosReparto")}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align='right' style={{ color: '#111111', fontSize: 14, fontWeight: 500, marginLeft: 10, marginRight: 10 }}>
                            {/*NumeroEsp(PrecioCliente(pantallaFinal.gastos, '21.00', valuesGlobales.ivaUsuario))*/}
                            {/*NumeroEsp(PrecioCliente(pantallaFinal.gastos, '001', formaPago.iva))*/}
                            {NumeroEsp(PrecioCliente(pantallaFinal.gastos, '004', formaPago.iva))} €
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.cabLineasPedido}>
                    <Grid item xs={10}>
                        <Typography style={{ color: '#111111', fontSize: 18, fontWeight: 600, marginLeft: 10 }} display="inline">
                            {t("Email.TotalPedido") + " - "}
                        </Typography>
                        <Typography style={{ color: '#c62828', fontSize: 12, fontWeight: 600, marginRight: 10 }} display="inline">
                            {/*valuesGlobales.ivaUsuario === 0 ? t("Email.IvaNoIncluido") : t("Email.IvaIncluido")*/}
                            {formaPago.iva === 0 ? t("Email.IvaNoIncluido") : t("Email.IvaIncluido")}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align='right' style={{ color: '#c62828', fontSize: 14, fontWeight: 500, marginLeft: 10, marginRight: 10 }}>
                            {/*NumeroEsp(importeTotal + PrecioCliente(pantallaFinal.gastos, '21.00', valuesGlobales.ivaUsuario))*/}
                            {/*NumeroEsp(importeTotal + PrecioCliente(pantallaFinal.gastos, '001', formaPago.iva))*/}
                            {NumeroEsp(importeTotal + PrecioCliente(pantallaFinal.gastos, '004', formaPago.iva))} €
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                {listaFechasPedidos.length > 0 ?
                    <Grid container className={classes.cabLineasPedido}>
                        <Grid item xs={12}>
                            <Typography style={{ color: '#111111', fontSize: 18, fontWeight: 600, marginLeft: 10, marginTop: 20, marginRight: 6 }} display="inline">
                                {t("Email.Repeticion") + ":"}
                            </Typography>
                            <Typography style={{ color: '#c62828', fontSize: 12, fontWeight: 400, marginLeft: 5 }} display="inline">
                                {listaFechasPedidos}
                            </Typography>
                        </Grid>
                    </Grid>
                    :
                    <Fragment />
                }
            </Grid>
        )
    }



    const datosEntrega = (
        carro.length > 0 &&
        <Fragment>
            <Grid container className={classes.datosEntrega}>
                <Grid container>
                    <Grid item xs={12} className={classes.cabEntrega}>
                        <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 14, fontWeight: 500 }} display="inline">
                            {fechasPedidos.length === 0 ?
                                t("Carro.FechaObligatoria")
                                :
                                t("Carro.FechaEntrega")
                            }:
                        </Typography>
                    </Grid>
                    <Grid container className={fechasPedidos.length === 0 ? classes.cabFechas2 : classes.cabFechas1}>
                        <Grid item xs={3}>
                            <BotonCalendario
                                //style={{color: ThemeGeroa.palette.primary.main, marginTop:-25, marginLeft: -10}}
                                style={{ color: ThemeGeroa.palette.primary.main }}
                                onClick={() => setOpenDialoFe(true)}
                            />
                            <FormControl>
                                <Dialog
                                    // si no hay fechas no dejar abrir el calendario. //
                                    open={openDialoFe && fechasEntregaMercado.length > 0}
                                    onClose={handleDialoFe}
                                >
                                    <DialogContent>
                                        <SelectorDias
                                            fechas={fechasEntregaMercado}
                                            repite={fechasPedidos}
                                            idioma={valuesGlobales.idioma === 'Es' ? 'es' : 'eu'}
                                            accion={handleSelectorDia}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <BotonSinSalto
                                            texto={"Aceptar"}
                                            accion={handleDialoFe}
                                        />
                                    </DialogActions>
                                </Dialog>
                            </FormControl>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container className={classes.fechaRepe}>
                                {fechasPedidos.map((f, i) => (
                                    <Grid item key={'fp' + i}>
                                        <Typography style={{ fontSize: 11, fontWeight: 500, marginTop: 0 }}>
                                            {/*console.log(' --- ', f, ' -- ', valuesGlobales.idioma)*/}
                                            {FechaLargaEsp(f.fecha, valuesGlobales.idioma)}
                                            <br />
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes.cabEntrega}>
                        <Typography
                            style={{ color: ThemeGeroa.palette.primary.main, fontSize: 14, fontWeight: 500 }} display="inline">
                            {direccion.actual === -1 ?
                                t("Carro.EntregaObligatoria")
                                :
                                t("Carro.Entrega")
                            }:
                        </Typography>
                    </Grid>
                    <Grid container>
                        <HomeIcon className={classes.casa} />
                        <Grid item xs={9} className={classes.dirEntrega}
                            style={direccion.actual === -1 ? { border: "2px solid red" } : {}}
                        >
                            <FormControl className={classes.formDirecciones}>
                                <InputLabel htmlFor="SelDir" /* corregir warning de Label */>
                                    {t("Carro.Direcciones")}
                                </InputLabel>
                                {direcciones.length > 0 &&
                                    <Select
                                        inputProps={{ id: "SelDir", name: "SelDir" } /* corregir warning de Label */}
                                        //id="SelDir"
                                        value={direccion.actual /* === -1 ? 0 : direccion.actual*/}
                                        onChange={handleChangeDireccion}
                                    >
                                        {direcciones.map((dir, i) => (
                                            <MenuItem key={"Dir" + i} value={i}>
                                                {dir.tipo === 2 ? t("Carro.MiDireccion") : dir.nombre}
                                            </MenuItem>
                                        ))}
                                        {valuesGlobales.emailUsuario !== "" &&
                                            objMercado.localidades.length > 0 &&
                                            (<div>
                                                <Divider />
                                                <Divider />
                                                <Divider />
                                                <MenuItem key={"Dir_Nueva"}
                                                    value={direcciones.length + 1}
                                                    onClick={handleNuevaDireccion}>
                                                    {t("Carro.NuevaDireccion")}
                                                </MenuItem>
                                            </div>)
                                        }
                                    </Select>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )

    const listaProductosCarro = (
        pantallaFinal === '' && carro.length > 0 &&
        <Fragment>
            <Grid container className={classes.cabeceraCarro}>
                <Grid item className={classes.cabeceraCarroLeft}>
                    <ShoppingBasketIcon className={classes.cesta} />
                    <Typography style={{ fontSize: '30px', color: ThemeGeroa.palette.primary.main, fontWeight: 600, marginTop: 20, marginLeft: 20 }} display="inline">
                        {t('Carro.CarroCompra')}
                    </Typography>
                    <Typography style={{ fontSize: '20px', color: ThemeGeroa.palette.primary.main, fontWeight: 600, marginTop: 20, marginLeft: 18 }} display="inline">
                        {NumeroEsp(importeTotal1(carro)) + " € "}
                    </Typography>
                </Grid>
                <Grid item className={classes.cabeceraCarroRight}>
                    {/*<BotonSinSalto
                            texto={t("Carro.Realizar")}
                            accion={handleRealizar}
                    />*/}
                    <Boton
                        texto={t("Carro.SeguirComprando")}
                        salto={"/MisProductos"}
                    />
                </Grid>
            </Grid>
            <Divider />
            {datosEntrega}
            <br />
            {carro.map((linea, i) => (
                <Grid key={"G" + linea.codigo}>
                    <Accordion className={classes.panelProducto}
                        key={linea.codigo}
                        expanded={true}
                    >
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid item className={classes.cabeceraProducto}>
                                <Grid item className={classes.fotoProducto}>
                                    <img
                                        alt={valuesGlobales.idioma === "Es" ? linea.description : linea.description2}
                                        width="60px"
                                        src={config.misImagenes + '/productos/' + linea.codigo + '.jpg'}
                                        onError={(event) => event.target.setAttribute("src", defaultImgProducto)} />
                                </Grid>
                                <Grid item className={classes.cabeceraProductoDatos}>
                                    <Typography style={{ color: '#1565c0', fontWeight: 500 }} display="inline" nowrap="true">
                                        {valuesGlobales.idioma === "Es" ? linea.description : linea.description2}
                                    </Typography>
                                    <br />
                                    <Typography style={{ color: '#1565c0', fontSize: 15, fontWeight: 500 }} display="inline">
                                        {t("MisProductos.CantidadAPedir")}:
                                    </Typography>
                                    <FormControlLabel
                                        aria-label="Acknowledge"
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        control={<SelectorCantidad
                                            key={'Ar' + linea.codigo}
                                            maximo={carro[i].existenciasActuales}
                                            valor={carro[i].canPedida}
                                            accion={handleChangeCantidadProductosCarro(linea.id, i)}
                                        />}
                                    />
                                    <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }} display="inline">
                                        {/*linea.unidad +" x "+ NumeroEsp(PrecioCliente(linea.precioCompra, linea.iva, valuesGlobales.ivaUsuario), -1) +" € "*/}
                                        {linea.unidad + " x " + NumeroEsp(PrecioCliente(linea.precioCompra, linea.iva, valuesGlobales.ivaUsuario), numDecimales) + " € "}

                                    </Typography>
                                    <Typography className={classes.importeProducto}>
                                        {t("MisProductos.ImporteProducto") + ": " + NumeroEsp(linea.canPedida * PrecioCliente(linea.precioCompra, linea.iva, valuesGlobales.ivaUsuario)) + " €"}
                                    </Typography>
                                    {linea.fechas !== undefined && linea.fechas.length !== 0 ?
                                        <Grid item className={classes.radioBoton}>
                                            <Typography style={{ color: '#c62828', fontSize: 14, fontWeight: 500 }} display="inline">
                                                {t("Carro.FechaEntregaEspecial")}:
                                            </Typography>
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={event => event.stopPropagation()}
                                                onFocus={event => event.stopPropagation()}
                                                //control={<RadioEntregas fechas={linea.fechas} lineaCarro={linea} idioma={idioma}/>}
                                                control={<RadioEntregasMercado lista={linea.fechas.slice(0, 3)} numero={3} />}
                                            />
                                        </Grid>
                                        :
                                        <div />
                                    }
                                </Grid>
                            </Grid>
                            <Hidden xsDown>
                                <Grid item className={classes.cabeceraProductor}>
                                    <Grid item className={classes.fotoProductor}>
                                        <img alt={linea.description} width="55px"
                                            src={config.misImagenes + '/shops/' + linea.tiendaCodigo + '.jpg'}
                                            onError={(event) => event.target.setAttribute("src", defaultImgProductor)}
                                        />
                                    </Grid>
                                    <Grid item className={classes.cabeceraProductorDatos}>
                                        <Typography style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                            {linea.tiendaPoblacion}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                            {linea.tiendaProvincia}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                            {linea.tiendaNombre}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Grid item className={classes.iconoPapelera}>
                                <DeleteForever
                                    style={{ color: '#c62828' }}
                                    fontSize="large"
                                    onClick={handleBorraProducto(i)}
                                />
                            </Grid>
                        </AccordionSummary>
                    </Accordion>
                </Grid>
            )
            )}
            <Divider />
            {carro.length > 0 ?
                <Grid container className={classes.pieCarro}>
                    <BotonSinSalto
                        texto={t("Carro.Realizar")}
                        accion={handleRealizar}
                    //accion={realizarPago}
                    // Importante: length < 1 en lugar de length = 0. //
                    //desactivado={fechasPedidos.length < 1}
                    />
                </Grid>
                :
                <Fragment />
            }
            {redirectIdentificarse ?
                <Redirect to='/Identificarse' />
                :
                <Fragment />
            }
            <Dialog
                open={dialogoError.hayError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("Carro.ErrorTitulo")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="Dialog1-Texto">
                        {dialogoError.tipo === 1 && t("Carro.ErrorTexto1")}
                        {dialogoError.tipo === 2 && t("Carro.ErrorTexto2") + ": " + NumeroEsp(importeMinimo) + " €"}
                        {dialogoError.tipo === 3 && t("Carro.ErrorTexto3")}
                        {dialogoError.tipo === 4 && t("Carro.ErrorTexto4")}
                        {dialogoError.tipo === 5 && t("Carro.ErrorTexto5")}
                        {dialogoError.tipo === 6 && t("Carro.ErrorTexto6")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BotonSinSalto
                        texto={t("Carro.DialogBotonAceptar")}
                        accion={handleDialogoError(dialogoError.tipo)}
                    />
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialog1}
                onClose={handleCloseDialog1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("Carro.Dialog1Titulo")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="Dialog1-Texto">
                        {t("Carro.Dialog1Texto")}
                    </DialogContentText>
                    <DialogContentText id="Dialog1-Fechas" style={{ color: '#c62828' }}>
                        {Object.keys(distintasFechas).length > 1 ?
                            <Fragment>
                                {distintasFechas.length + " " + t("Carro.Pedidos")}
                                <br />
                                {distintasFechas.map((f) => (
                                    <Fragment key={f}>
                                        {f}
                                        <br />
                                    </Fragment>
                                ))}
                            </Fragment>
                            :
                            <Fragment />
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BotonSinSalto
                        texto={t("Carro.DialogBotonCancelar")}
                        accion={handleCloseDialog1}
                    />
                    <BotonValidacion
                        texto={t("Carro.DialogBotonAceptar")}
                        validacion={handleAceptarDialog1}
                        salto={"/"}
                    />
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialog2}
                onClose={handleCloseDialog2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("Carro.Dialog2Titulo")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("Carro.Dialog2Texto")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BotonValidacion
                        texto={t("Carro.DialogBotonAceptar")}
                        validacion={handleAceptarDialog2}
                        salto={"/"}
                    />
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialog4}
                onClose={handleCloseDialog4}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("Carro.Dialog4Titulo")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="Dialog4-Texto">
                        {t("Carro.Dialog4Texto") + ": "}
                    </DialogContentText>
                    <DialogContentText id="Dialog4-Fechas" style={{ color: '#c62828', marginTop: -10 }}>
                        {fechasPedidos.length + " " + t("Carro.Pedidos")}
                        <br />
                        {fechasPedidos.map(f => (
                            <Fragment key={FechaLargaEsp(f.fecha, valuesGlobales.idioma)}>
                                {FechaLargaEsp(f.fecha, valuesGlobales.idioma)}
                                <br />
                            </Fragment>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BotonValidacion
                        texto={t("Carro.DialogBotonAceptar")}
                        validacion={handleAceptarDialog4}
                        salto={"/"}
                    />
                </DialogActions>
            </Dialog>
            <Dialog
                open={direccion.nueva}
                //onClose={handleCloseDialogNueva}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <NuevaDireccion />
            </Dialog>
        </Fragment>
    )

    let fechaEntrega = ""
    let importeTotal = 0
    const pantallaDatos = (
        pantallaFinal !== '' &&
        <Fragment>
            {objMercado.logistica === "Completa" &&
                <Fragment>
                    <Grid container className={classes.cabLineasPedido}>
                        <RadioBotonesTipoDePago
                            fpMercado={formasDePagoFiltradas}
                            usRecibo={jwt.user.recibo}
                            usTransferencia={jwt.user.transferencia}
                            repeticiones={pantallaFinal.repeticiones > 1}
                            validacion={cambioEnFormaDePago}
                            onChange={(valorSeleccionado) => setFormaPagoSeleccionada(valorSeleccionado)}
                        />

                    </Grid>
                    <br />
                    <br />
                </Fragment>
            }
            <Grid container className={classes.cabLineasPedido}>
                <Grid item xs={6}>
                    <Typography style={{
                        color: '#846b31',
                        fontSize: 18,
                        fontWeight: 600,
                        marginLeft: 10
                    }}>
                        {t("Email.Descripcion")}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography align='right'
                        style={{
                            color: '#846b31',
                            fontSize: 18,
                            fontWeight: 600,
                            marginRight: 10
                        }}>
                        {t("Email.Cantidad")}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography align='right'
                        style={{
                            color: '#846b31',
                            fontSize: 18,
                            fontWeight: 600,
                            marginRight: 10
                        }}>
                        {t("Email.Precio")}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography align='right'
                        style={{
                            color: '#846b31',
                            fontSize: 18,
                            fontWeight: 600,
                            marginRight: 10
                        }}>
                        {t("Email.Total")}
                    </Typography>
                </Grid>
            </Grid>
            {pantallaFinal.products
                .map((linea, i) => {
                    let cabecera = <div />
                    let pie = <div />
                    if ('' + fechaEntrega !== '' + linea.fechaEntrega) {
                        if (i === 0) {
                            cabecera = CabeceraFormada(linea.fechaEntrega)
                        } else {
                            cabecera = (
                                <Fragment>
                                    {PieFormado(linea.fechaEntrega)}
                                    <br />
                                    {CabeceraFormada(linea.fechaEntrega)}
                                </Fragment>
                            )
                            importeTotal = 0
                        }
                    }
                    //importeTotal += linea.quantity * PrecioCliente(linea.price, linea.iva, valuesGlobales.ivaUsuario)
                    importeTotal += linea.quantity * PrecioCliente(linea.price, linea.iva, formaPago.iva)
                    if (i === pantallaFinal.products.length - 1) {
                        pie = PieFormado(new Date(linea.fechaEntrega))
                    }
                    fechaEntrega = linea.fechaEntrega
                    return (
                        <Fragment key={linea.product}>
                            {cabecera}
                            <Grid container className={classes.cabLineasPedido}>
                                <Grid item xs={6}>
                                    <Typography style={{
                                        color: '#616161',
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginLeft: 10,
                                        marginRight: 10
                                    }}>
                                        {linea.description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography align='right'
                                        style={{
                                            color: '#616161',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            marginRight: 10
                                        }}>
                                        {NumeroEsp(linea.quantity)}  {linea.unidad}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography align='right'
                                        style={{
                                            color: '#616161',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            marginRight: 10
                                        }}>
                                        {/*NumeroEsp(PrecioCliente(linea.price, linea.iva, valuesGlobales.ivaUsuario), -1)*/}
                                        {NumeroEsp(PrecioCliente(linea.price, linea.iva, formaPago.iva), numDecimales)} €
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography align='right'
                                        style={{
                                            color: '#616161',
                                            fontSize: 14,
                                            fontWeight: 500,
                                            marginRight: 10
                                        }}>
                                        {/*NumeroEsp(linea.quantity * PrecioCliente(linea.price, linea.iva, valuesGlobales.ivaUsuario))*/}
                                        {NumeroEsp(linea.quantity * PrecioCliente(linea.price, linea.iva, formaPago.iva))} €
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container className={classes.cabLineasPedido}>
                                {pie}
                            </Grid>
                        </Fragment>
                    )
                })
            }
            <br />
            <Grid container justifyContent="flex-end">
                <Grid item style={{ marginLeft: 10 }}>
                    <BotonSinSalto
                        texto={t("Carro.Volver")}
                        accion={NoTramitarPedido}
                    />
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>
                    {console.log("forma de pago seleccionada: " + formaPagoSeleccionada)}
                    {["Tarjeta", "Txartela", "J"].includes(formaPagoSeleccionada) ?
                        <MuiThemeProvider theme={ThemeGeroa}>
                            <form id="miForm01" action={config.urlRedSys} method="POST" noValidate autoComplete="off">
                                <input hidden readOnly name="Ds_SignatureVersion" value={valoresPago.mi_Ds_SignatureVersion} />
                                <input hidden readOnly name="Ds_MerchantParameters" class="form-control" value={valoresPago.mi_Ds_MerchantParameters} />
                                <input hidden readOnly name="Ds_Signature" class="form-control" value={valoresPago.mi_Ds_Signature} />
                                <input
                                    type="submit"
                                    value={t("Carro.Confirmar")}
                                    className={classes.botonSubmit}
                                    onClick={async (e) => {
                                        e.preventDefault()
                                        realizarPago()
                                    }}
                                />
                            </form>
                        </MuiThemeProvider>
                        :
                        <Boton
                            texto={t("Carro.Confirmar")}
                            salto={"#/FinalizarPago"}
                            onClick={() => realizarPago()}
                        />
                    }
                </Grid>
            </Grid>
            {redirectIdentificarse ?
                <Redirect to='/Identificarse' />
                :
                <Fragment />
            }
        </Fragment>
    )

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Fragment>
                <Container maxWidth="md" className={classes.registro}>
                    {listaProductosCarro}
                    {pantallaDatos}
                </Container>
            </Fragment>
        </MuiThemeProvider>
    );
}







export default withTranslation()(CarroCompra)