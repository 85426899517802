import React from 'react'
import { Route, Switch } from 'react-router-dom'
//import { useStore} from 'react-hookstore';
//import config from './../config/config'

// Pagina 404
import PageNotFound from '../views/page404.js'

// Identificarse - Usuarios - Password
import Identificarse from "../views/Login/Identificarse.js";
import Password from "../views/Login/Password.js"
import NuevoPassword from "../views/Login/NuevoPassword.js"
import Usuarios from "../views/Usuarios/Usuarios.js"

// Mapa
import MisMapas from "../views/Mapas/MisMapas.js"

// Mercados
//import Mercados from "../views/Entrada/Mercados.js"
import MisMercados from "../views/Entrada/MisMercados.js"

// Mis Productos
import MisProductos from "../views/MisProductos/MisProductos.js"

// Carro Compra
import CarroCompra from "../views/CarroCompra/CarroCompra.js"
import FinalizarPago from "../views/CarroCompra/FinalizarPago.js"
import PagoCancelado from "../views/CarroCompra/PagoCancelado.js"

// Mis Pedidos
import MisPedidos from "../views/MisPedidos/MisPedidos.js"

// Mis Facturas
import MisFacturas from "../views/MisFacturas/MisFacturas.js"

//Legal
import Cookies from '../views/Legal/Cookies.js'
import AvisoLegal from '../views/Legal/AvisoLegal.js'
import Condiciones from '../views/Legal/Condiciones.js'

// Ruta protegida
import RutaMercadoProtegido from './RutaMercadoProtegido.js'



export default function IndexRoutes() {

  //const [valuesGlobales, ] = useStore('DatosGlobales');

  //console.log('VG en rutas = ', valuesGlobales.origenRedsys)

  return (
    <Switch>
      {/*Route para Inicio*/}
      {/*<Route exact path="/sarekocli" component={Mercados} />*/}
      <Route exact path="/" component={MisMapas} />
      <Route exact path="/MisMapas" component={MisMapas} />
      

      {/*Route para Mercados*/}
      <Route exact path="/MisMercados" component={MisMercados} />
      {/*<Route exact path="/Mercados" component={Mercados} />*/}
      {/*<Route exact path={config.subRutaEneekCorderos} render={(props) => <Mercados {...props} origen={`EneekCorderos`} />} />*/}

      {/*Routes para Identificarse - crear Usuario - Olvido de Password*/}
      <Route path="/Identificarse" component={Identificarse} />
      <Route path="/Usuarios" component={Usuarios} />
      <Route path="/Password" component={Password} />
      <Route path="/NuevoPassword" component={NuevoPassword} />

      {/*Routes para Mis Productos*/}
      <Route path="/MisProductos" component={MisProductos} />
      <RutaMercadoProtegido path="/mis-productos/:mercadoPorParam" component={MisProductos} />

      {/*Routes para Carro de la Compra*/}
      <Route path="/CarroCompra" component={CarroCompra} />
      <Route path="/FinalizarPago" component={FinalizarPago} />
      <Route path="/PagoCancelado" component={PagoCancelado} />

      <Route path='/index' exact />

      {/*Routes para Mis Pedidos*/}
      <Route path="/MisPedidos" component={MisPedidos} />

      {/*Routes para Mis Facturas*/}
      <Route path="/MisFacturas" component={MisFacturas} />

      {/*Routes para Legal*/}
      <Route path="/Cookies" component={Cookies} />
      <Route path="/AvisoLegal" component={AvisoLegal} />
      <Route path="/Condiciones" component={Condiciones} />

      {/*Route para Página No Encontrada (404)*/}
      <Route component={PageNotFound} />

    </Switch>
  );
};