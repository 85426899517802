import React, { Fragment, useState, useEffect } from "react";
import { useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'

import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { listaMercadosClientes } from './../../apis/api-mercado.js'

import BotonMercado from "../../components/botones/BotonMercado.js";
import BotonSinSalto from './../../components/botones/BotonSinSalto'
import ThemeGeroa from './../../components/ThemeGeroa.js'

import { BuscaIndice, DistanciaKm, OrdenaArray2Campos } from './../../config/funcionesPropias.js'


import geolocation from 'geolocation'

const MisMercados = ({ t }) => {
  const [errorDinahosting, setErrorDinahosting] = useState(false)
  const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');

  const [datos, setDatos] = useState({
    mercados: [],
  })

  // Si hay algo en mercadosMapa filtrar solo esos
  let dataR = []

  // ------------------------------------------------------------ //
  // Documentación: https://es.reactjs.org/docs/hooks-effect.html //
  // ------------------------------------------------------------ //
  useEffect(() => {
    // Cargar Datos. //
    loadMercados()

    // El array vacío indica que sólo se se llama a useEffect 1 vez.
    // https://es.reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
    // Recargar cuando el usuario se identifica (cambia emailUsuario)
    // Así se cargarán los datos de Mercados del Usuario.

    // Para que ignore el error: React Hook useEffect has a missing dependency: 'loadMercados'
    // eslint-disable-next-line
  }, [])
  //}, [valuesGlobales.emailUsuario])

  async function loadMercados() {
    // Guarda la pantalla en la que estamos
    // No hacer con setValuesGlobales porque no
    // actualiza los mercados que se cargan luego.
    //setValuesGlobales({ ...valuesGlobales, paginaOrigen: '/'})
    valuesGlobales.paginaOrigen = '/MisMercados'


    // Geolocalizacion
    let localiz = { la: 0, lo: 0 }
    await geolocation.getCurrentPosition(function (err, position) {
      if (err) {
        localiz = { la: 0, lo: 0 }
      }
      else {
        localiz = { la: position.coords.latitude, lo: position.coords.longitude }
      }
    })
    try {
      ////////////////////////////////
      // Leer las Tarifas-Mercados. //
      ////////////////////////////////
      //            const dataM = await listTarifas()
      let dataM = await listaMercadosClientes()
      //console.log('dataM = ', dataM)


      // Si hay algo en mercadosMapa filtrar solo esos
      if (valuesGlobales.mercadosMapa.length > 0) {
        for (let i = 0; i <= dataM.length - 1; i++) {
          for (let j = 0; j <= valuesGlobales.mercadosMapa.length - 1; j++) {
            if (dataM[i].name === valuesGlobales.mercadosMapa[j].MerCod) {
              dataR.push(dataM[i])
            }
          }
        }

        //let mm = []
        //valuesGlobales.mercadosMapa = []
        //setValuesGlobales({ ...valuesGlobales, mercadosMapa: mm })
        dataM = dataR
      }


      let activos = dataM.filter((mercado) => mercado.activo)

      // Enseñar los mercados necesarios si el Usuario está identificado y no se viene del mapa
      let mercados
      if (dataR.length === 0) {
        mercados = valuesGlobales.mercadosUsuario
      } else {
        mercados = dataM
      }

      //console.log('mercados = ', mercados)

      // Estamos identificados -> Abiertos + Cerrados de ese usuario y no venimos del mapa
      if (dataR.length === 0) {
        activos = activos.filter((mercado) => (
          mercado.abierto ||
          mercados.indexOf(mercado.name) !== -1
        )
        )
      }


      //console.log('mercados activos = ', activos)

      // Ordena los Mercados
      CalculaDistanciasMercados(activos, localiz)
      let listaMercados = OrdenaArray2Campos(activos, "cercania", "orden")
      // Guarda los mercados en datos globales.
      //valuesGlobales.mercados = listaMercados
      setValuesGlobales({ ...valuesGlobales, mercados: listaMercados })

      // Guarda los mercados en localStorage. Para que refresque en productos.
      localStorage.setItem('mercados', JSON.stringify(listaMercados))

      //setMercados(listaMercados)
      datos.mercados = listaMercados
      //console.log('mercados en la entrada = ', datos.mercados)

      //////////////////////
      // Forzar Repintado //
      //////////////////////
      setDatos({ ...datos })
    } catch (error) {
      // Pintar Venta de que Contacte con el Administrador -- Dinahosting MAL
      setErrorDinahosting(true)
      console.error("Error. Contactar con el administrador", error)
    }
  }


  const handleCloseDialogError = () => {
    var win = window.open("about:blank", "_self");
    win.close();
  }


  function CalculaDistanciasMercados(listaMer, origen) {
    //let mercadosOrdenados = []
    let distancia
    // Punto Origen Seleccion
    listaMer.map((mercado, i) => {
      mercado.cercania = 999999
      // Localidades
      mercado.localidades.map((localidad, j) => {
        distancia = DistanciaKm(origen, { la: localidad.latitud, lo: localidad.longitud })
        // Añadir la cercanía
        if (mercado.cercania > distancia) {
          mercado.cercania = distancia
        }
        return null
      })

      // Puntos de Recogida
      mercado.puntosDeRecogida.map((punto, j) => {
        distancia = DistanciaKm(origen, { la: punto.localidad.latitud, lo: punto.localidad.longitud })
        // Añadir la cercanía
        if (mercado.cercania > distancia) {
          mercado.cercania = distancia
        }
        return null
      })

      return null
    })
    // Gancho con los mercados ordenados por cercanía. //
    setDatos({ ...datos, mercados: OrdenaArray2Campos(listaMer, "cercania", "orden") })
  }


  //to={valuesGlobales.emailUsuario !== "" ? "/MisProductos" : "/Identificarse"}>

  function ponSalto(m) {
    /*
    let s = ''
    if(m.abierto){
      s = "/MisProductos"
    } else if(valuesGlobales.nombreUsuario === ''){
      s = "/Identificarse"
    } else{
      s = "/MisProductos"
    }
    return s
    */
    // esto es lo mismo en 2 instrucciones
    /*
    if (m.abierto || valuesGlobales.nombreUsuario !== '') return "/MisProductos"
    return "/Identificarse"
    */
    // esto es lo mismo en 1 instrucción: Si abierto o identificado ... //
    return (m.abierto || valuesGlobales.nombreUsuario !== '') ? "/MisProductos" : "/Identificarse"
  }

  function ponDesactivado(m) {
    /*
    let s = true
    if(valuesGlobales.mercadoActual === undefined || valuesGlobales.mercadoActual === '' || valuesGlobales.mercadoActual === m.name){
      s = false
    }
    return s
    */
    // esto es lo mismo en 1 instrucción: Desactiva si no hay mercadoActual o es distinto al actual. //
    return !(valuesGlobales.mercadoActual === undefined ||
      valuesGlobales.mercadoActual === '' ||
      valuesGlobales.mercadoActual === m.name)
  }

  // Ver si podemos entrar en el mercado. //
  const valida = (intentoMercado) => {
    //let index = BuscaIndice(valuesGlobales.mercados, 'name', valuesGlobales.intentoMercado)
    let index = BuscaIndice(valuesGlobales.mercados, 'name', intentoMercado)
    // Permite acceder si es:
    //   -- Mercado Abierto
    //   -- mercado Cerrado y pertenezco a él
    //let retorno = true
    let retorno = (index >= 0)
    if (index >= 0 && valuesGlobales.mercados[index].abierto === false) {
      if (valuesGlobales.emailUsuario !== "") {
        //if(valuesGlobales.mercadosUsuario.indexOf(valuesGlobales.intentoMercado) === -1){
        if (valuesGlobales.mercadosUsuario.indexOf(intentoMercado) === -1) {
          retorno = false
        }
      }
    }
    return retorno
  }



  // Clave aleatoria. para cambiar DOM
  let clave = Math.round(Math.random() * 100);


  // Por si hay error de Conexion a Dinahosting   
  const errorConexionDinahosting = (
    errorDinahosting ?
      <Dialog
        open={true}
        //onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Mercados.TituloError")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Mercados.TextoError")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BotonSinSalto
            texto={t("Mercados.BotonError")}
            accion={handleCloseDialogError}
          />
        </DialogActions>
      </Dialog>
      :
      <div />
  )


  return (
    <MuiThemeProvider theme={ThemeGeroa}>
      <Grid>
        <br />
        <Container maxWidth="md">
          {errorConexionDinahosting}
          <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600 }} display="inline">
            {valuesGlobales.punto === "" ? t("Mercados.MisMercados") : t("Mercados.MisMercadosPunto") + " " + valuesGlobales.punto}
          </Typography>
          <br />
          <br />
          <br />
          {datos.mercados.length !== 0 ?
            datos.mercados.map((mercado, i) => (
              <Grid item key={"Mercado" + i}>
                <BotonMercado
                  key={clave + mercado.name + i}
                  mercado={mercado}
                  validacion={valida}
                  desactivado={ponDesactivado(mercado)}
                  salto={ponSalto(mercado)}
                />
                <br />
              </Grid>
            ))
            :
            <Fragment />
          }
        </Container>
        <br />
      </Grid>
    </MuiThemeProvider>
  );
}

export default withTranslation()(MisMercados)