import React from "react";
import { renderEmail } from 'react-html-email'
import EmailPedidoUsuario from './../views/Emails/EmailPedidoUsuario.js'
import config from './../config/config.js'

const rutaApi = config.rutaApisCliente + '/emails'

const enviaEmail = (emailDestino, pedido, tipoEmail, subject, credentials) => {
  let subject1 = ""
  let messageHtml1 = ""
  let subject2 = ""
  let messageHtml2 = ""

  //      
  if (tipoEmail === "1") {
    //
    subject1 = subject
    messageHtml1 = renderEmail(<EmailPedidoUsuario pedido={pedido}></EmailPedidoUsuario>)
    subject2 = subject
    messageHtml2 = renderEmail(<EmailPedidoUsuario pedido={pedido}></EmailPedidoUsuario>)
  }
  return fetch(rutaApi + '/enviaEmail/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Allow-Control-Allow-Origin": 'http://localhost:3000',
      'Authorization': 'Bearer ' + credentials.t
    },
    credentials: 'include',
    body: JSON.stringify({
      email: emailDestino,
      subject1: subject1,
      mensaje1: messageHtml1,
      subject2: subject2,
      mensaje2: messageHtml2
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}



const enviaEmailPassword = (emailDestino, idUsuario) => {
  let subject = "Cambio de Password"
  let messageHtml = 'Código para cambio de contraseña : ' + idUsuario

  return fetch(rutaApi + '/enviaEmailPassword/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //credentials: 'include',
    body: JSON.stringify({
      email: emailDestino,
      subject: subject,
      mensaje: messageHtml,
      id: idUsuario
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}



const enviaEmailPeticion = (cliEma, cooEma, codMer, nomMer) => {

  let msgEU, msgES
  // Mensaje Cliente. //
  let subject1 = "Alta eskaera / Solicitud de alta"
  msgEU = "Mila esker, zure alta eskaera behar bezala jaso da. "
  msgEU += "Hurrengo egunetan merkatu horretako koordinatzailearen emaila jaso beharko zenuke. "
  msgEU += "Ez bazenu erantzunik jasoko idatzi edota deitu eta guk aztertuko dugu egoera "
  msgEU += "sustatu@ekolurra.eus edo telf. 946271594. Ondo izan. ENEEK-Ekolurrako komunikazio saila. "
  msgES = "Muchas gracias, su solicitud de alta se ha recibido correctamente. "
  msgES += "En los próximos días deberías recibir un email de la persona coordinadora del mercado. "
  msgES += "En caso de no recibir respuesta puedes escribir o llamar a ENEEK-Ekolurra y solucionaremos la situación, "
  msgES += "sustatu@ekolurra.eus o telf. 946271594. Un saludo. Departamento de comunicación de ENEEK-Ekolurra."
  // Formatear HTML. //
  let msgHtml1 = renderEmail(
    <html>
      <body>
        <p>{msgEU}</p>
        <hr width="100%" size="2"></hr>
        <p>{msgES}</p>
      </body>
    </html>
  )

  // Mensaje Coordinador. //
  let subject2 = "Bezero Baten alta eskaera / Solicitud de alta cliente"
  msgEU = "Kaixo! Bezero berri batek alta eskaera eskatu dizu "
  msgEU += codMer + " " + nomMer + " merkatuan. "
  msgEU += "Ahal bezain bizkorren erantzun zazu mesedez. "
  msgEU += "Ondo izan. ENEEK-Ekolurrako komunikazio saila."
  msgES = "¡Hola! Un nuevo cliente ha solicitado el alta en el "
  msgES += "mercado " + codMer + " " + nomMer + ". "
  msgES += "Por favor, contesta lo más rápido que puedas. "
  msgES += "Un saludo. Departamento de comunicación de ENEEK-Ekolurra."
  // Formatear HTML. //
  let msgHtml2 = renderEmail(
    <html>
      <body>
        <p>{msgEU}</p>
        <hr width="100%" size="2"></hr>
        <p>{msgES}</p>
      </body>
    </html>
  )

  return fetch(rutaApi + '/enviaEmailPeticion/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //credentials: 'include',
    body: JSON.stringify({
      email1: cliEma,
      subject1: subject1,
      mensaje1: msgHtml1,
      email2: cooEma,
      subject2: subject2,
      mensaje2: msgHtml2
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}



export {
  enviaEmail,
  enviaEmailPassword,
  enviaEmailPeticion
}