import config from './../config/config.js'


const rutaApi = config.rutaApisCliente+'/mercados'

const listaMercadosClientes = () => {
  return fetch(rutaApi+'/listaMercadosClientes', {
    method: 'GET',
  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}


export {
  listaMercadosClientes
}
