import config from './../../config/config.js'
//import { FechaAaMmDdHhMmSs } from './../../config/funcionesPropias.js'
//import { PrecioCliente} from './../../config/funcionesParticulares.js'

const cryptojs = require('crypto-js');
const base64url = require('base64url')


/*
function cargaDatosPagoTarjeta(pedidos, idioma, ivaUsuario) {
    let pedido = pedidos[0]
    // calcula los gastos totales
    let gastosTotales = gastosRepartos(pedidos, ivaUsuario)
    let totalTarjeta = ((Number(pedido.total)+gastosTotales)*100).toString()
    let posicionComa = totalTarjeta.indexOf('.')
    if(posicionComa !== -1){ 
        totalTarjeta = totalTarjeta.substr(0, totalTarjeta.indexOf('.'))
    }
    let numeroPedido = pedido.customer_codigo.substr(pedido.customer_codigo.length-2,2) + FechaAaMmDdHhMmSs().substr(2)
*/


function cargaDatosPagoTarjeta(totalTarjeta, numeroPedido, cliente, codigoIdioma) {

    const mParams = {
        "DS_MERCHANT_AMOUNT": totalTarjeta,
        "DS_MERCHANT_ORDER": numeroPedido,
        "DS_MERCHANT_CONSUMERLANGUAGE": codigoIdioma,
        "DS_MERCHANT_MERCHANTCODE": config.paramsRedsys.DS_MERCHANT_MERCHANTCODE,
        "DS_MERCHANT_MERCHANTNAME": config.paramsRedsys.DS_MERCHANT_MERCHANTNAME,
        "DS_MERCHANT_CURRENCY": config.paramsRedsys.DS_MERCHANT_CURRENCY,
        "DS_MERCHANT_TRANSACTIONTYPE": config.paramsRedsys.DS_MERCHANT_TRANSACTIONTYPE,
        "DS_MERCHANT_TERMINAL": config.paramsRedsys.DS_MERCHANT_TERMINAL,
        "DS_MERCHANT_PRODUCTDESCRIPTION": cliente,
        "DS_MERCHANT_MERCHANTURL": config.paramsRedsys.DS_MERCHANT_MERCHANTURL,
        "DS_MERCHANT_URLOK": config.paramsRedsys.DS_MERCHANT_URLOK,
        "DS_MERCHANT_URLKO": config.paramsRedsys.DS_MERCHANT_URLKO
        }
    return generaDatosPago(mParams, config.paramsRedsys.clave)
}


/*
function gastosRepartos(listaP, ivaUsuario){
    let retorno = 0
    listaP.map((p,i) =>{
        if (p.restaExistencias){
            retorno = retorno + PrecioCliente(p.gastos, "21.00", ivaUsuario)
        }
        return null
    })
    return retorno
}
*/

function generaDatosPago(tpvdata, merchant_key){
    console.log("TPV Data: " + JSON.stringify(tpvdata))
    console.log("Merchant key: " + merchant_key)
    // Base64 encoding of parameters
    var merchantWordArray = cryptojs.enc.Utf8.parse(JSON.stringify(tpvdata));
    var merchantBase64 = merchantWordArray.toString(cryptojs.enc.Base64);

    // Decode key
    var keyWordArray = cryptojs.enc.Base64.parse(merchant_key);

    // Generate transaction key
    var iv = cryptojs.enc.Hex.parse("0000000000000000");
    var cipher = cryptojs.TripleDES.encrypt(tpvdata.DS_MERCHANT_ORDER, keyWordArray, {
        iv:iv,
        mode: cryptojs.mode.CBC,
        padding: cryptojs.pad.ZeroPadding
    });

    // Sign
    var signature = cryptojs.HmacSHA256(merchantBase64, cipher.ciphertext);
    var signatureBase64 = signature.toString(cryptojs.enc.Base64);

    // Done, we can return response
    var response = {
        signatureVersion: "HMAC_SHA256_V1",
        merchantParameters: merchantBase64,
        signature: signatureBase64
    };
    return response
}


function decodeMerchantParameters(data) {
    const _data = JSON.parse(base64url.decode(data, 'utf8'));
    const res = {};
    for (var name in _data) {
      res[decodeURIComponent(name)] = decodeURIComponent(_data[name]);
    }
    return res;
}


export {
    cargaDatosPagoTarjeta,
    generaDatosPago,
    decodeMerchantParameters
}