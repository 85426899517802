import config from './../config/config.js'

const rutaApi = config.rutaApisCliente + '/pueblos'

const listaPueblos = () => {
  return fetch(rutaApi + '/listaPueblos', {
    method: 'GET',
  }).then(response => {
    return response.json()
  }).catch((err) => console.log(err))
}


export {
  listaPueblos
}
