const config = {
  port: process.env.REACT_APP_MY_PORT,
  misImagenes: process.env.REACT_APP_RUTA_FOTOS,    
  misImagenesPublicas: process.env.REACT_APP_API_IMAGENES_PUBLICAS, 
  miServidor: process.env.REACT_APP_API_CLIENTE + ":" + process.env.REACT_APP_MY_PORT,
  rutaApis: "",
  rutaCarpetaFicherosParaServidor: "./../../www/"+ process.env.REACT_APP_RUTA_FICHEROS,
  rutaCarpetaFicherosparaWebs: process.env.REACT_APP_API_CLIENTE + "/"+process.env.REACT_APP_RUTA_FICHEROS,
  rutaXls: "/_xlsx",
  rutaPdfReparto: "/_pdfReparto",
  rutaPdfCliente: "/_pdfCliente",
  miCliente: process.env.REACT_APP_API_CLIENTE,
  rutaApisCliente: process.env.REACT_APP_API_CLIENTE_PORT,
  jwtSecret: "1234567890",

  //
  passMapas: process.env.REACT_APP_MAPAS,
     
  /////////////////
  // Para envio de Mails
  emails: {
    smtp: process.env.REACT_APP_SMTP,
    port: process.env.REACT_APP_SMTP_PORT,
    user: process.env.REACT_APP_SMTP_MAIL,
    pass: process.env.REACT_APP_SMTP_PASS,
  },
  /////////////////
  // Certificados de la página
  certificado: {
    key: process.env.REACT_APP_CER_KEY,
    crt: process.env.REACT_APP_CER_CER
  },

  /////////////////
  // Cuenta de mail para envio de correos en desarrollo --> soporte@geroa.com
  //envioEmailsDesarrollo: configuracion === "Sareko" ? '' : 'soporte@geroa.com',
  envioEmailsDesarrollo: process.env.REACT_APP_MAIL_DESARROLLO,

  /////////////////
  // Notificaciones a Usuarios Activas
  //notificaciones: configuracion === "Sareko" ? false : true,
  notificaciones: false,

  //////
  // Parámetros FIJOS del pago con tarjeta a traves de redsys
  // Geroa en https:\\geroakoa.eus (Pruebas)
  // Ekulurra en https:\\sareko.net (Real)
  urlRedSys: process.env.REACT_APP_MAIL_URL_RED_SYS,
  paramsRedsys: {
    DS_MERCHANT_MERCHANTCODE: process.env.REACT_APP_RED_SYS_MERCHANTCODE,                          // Ekolurra (sareko.net) o Geroa (geroakoa.eus)
    DS_MERCHANT_CURRENCY:"978",                                                                    // Euros
    DS_MERCHANT_MERCHANTNAME: process.env.REACT_APP_RED_SYS_MERCHANTNAME,                          // Nombre que aparece
    DS_MERCHANT_TRANSACTIONTYPE:"0",                                                               // Tipo de Operación
    DS_MERCHANT_TERMINAL:"028",                                                                    // Terminal: siempre 1 // Unai: Entorno de pruebas es 40.
    DS_MERCHANT_MERCHANTURL: process.env.REACT_APP_URL_TARJETA,                                    // Pagina Inicio
    DS_MERCHANT_URLOK: process.env.REACT_APP_URLOK + "/#/FinalizarPago",                     // Pagina Pago Bien 
    DS_MERCHANT_URLKO: process.env.REACT_APP_URLOK + "/#/PagoCancelado",                     // Pagina Pago Cancelado
    clave: process.env.REACT_APP_MAIL_RED_SYS_CLAVE
   },



  // Datos para la Tarketa de Prueba
  // 4548812049400004
  // Caducidad: 12/34
  // Código CVV2: 123
  //
  // Pagina de Redsys pruebas
  // https://sis-t.redsys.es:25443/admincanales-web/index.jsp#/login
  // Usuario: 058348996-001
  // Contraseña: Geo701645_2_1
  // Documentación: https://pagosonline.redsys.es/conexion-redireccion.html
  //
  // Pagina de Redsys REAL geroakoa
  //Su nueva clave de comercio SHA-256 es la siguiente:BfUqDDV3S7LSRTrBMq/48aZOKXzyfqd5
  //
  



  //Accesos externos que llevan filtro puesto
  // Corderos
  subRutaEneekCorderos: "/cs",
  filtroEneekCorderos: "EUSKARRIAK/SOPORTE",
}

export default config


